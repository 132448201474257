import React from "react"
import { Route, Switch } from "react-router-dom"

// import BumeService from "@/services/Bume"

import PageList from "@/pages/PageList"
import PageEdit from "@/pages/PageList/PageEdit"

import TemplatePageCategoryList from "@/pages/TemplatePageCategoryList"
import TemplatePageList from "@/pages/TemplatePageCategoryList/TemplatePageList"

import SiteList from "@/pages/SiteList"

import Account from "@/pages/Account"

export const PrivateCPRoutes = () => {
	// BumeService.startIntercom()

	return (
		<Switch>
			<Route exact path="/site/list" component={SiteList} />
			<Route exact path="/customer/account" component={Account} />
		</Switch>
	)
}

export const SitePrivateCPRoutes = () => {
	// BumeService.startIntercom()

	return (
		<Switch>
			<Route exact path="/sites/:siteId/pages" component={PageList} />
			<Route exact path="/sites/:siteId/pages/:pageId/edit" component={PageEdit} />

			<Route exact path="/sites/:siteId/template-page-categories" component={TemplatePageCategoryList} />
			<Route exact path="/sites/:siteId/template-page-categories/:templatePageCategoryId" component={TemplatePageList} />
		</Switch>
	)
}
