import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import { useParams } from "react-router-dom"

import useDidMount from "@/hooks/useDidMount"

import { Loading } from "@/components"

import StoryCarousel from "@/pages/FP/StoryCarousel"

import PageTemplate from "@/@fp/templates/PageTemplate"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import MetricService from "@/services/Metric"

import { SiteData } from "@/protocols/site"

import useStyles from "@/pages/FP/styles"

import { retrieveSubdomain, getQueryParam } from "@/utils/url"

import { Profile1Props } from "@/@fp/sections/Profile1"

import { Page } from "@/@fp/protocols/Page"
import { SiteSettings, SiteIntegration } from "@/@fp/protocols/Site"

import { useGlobalStateStore } from "@/store/GlobalState"

import SiteError, {
	noActivePageFoundIllustration,
	siteNotFoundIllustration
} from "@/pages/FP/SiteError"

type SiteError = "not-found" | "no-active-pages-found" | null

type Extradata = {
	siteId: number
	customerId: number
}

const FP = () => {
	const [extradata, setExtradata] = useState<Extradata>({} as Extradata)
	const [pages, setPages] = useState<Page[]>([])
	const [siteSettings, setSiteSettings] = useState<SiteSettings>({} as SiteSettings)
	const [siteIntegrations, setSiteIntegrations] = useState<SiteIntegration[]>([])
	const [siteError, setSiteError] = useState<SiteError>(null)
	const [loading, setLoading] = useState(true)
	const [initialPageIndex, setInitialPageIndex] = useState(0)

	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()
	const { slug } = useParams<{ slug: string }>()

	const	siteSlug = slug || retrieveSubdomain()

	const onSlideChange = (index: number) => {
		const page = pages.find((_, pageIndex) => pageIndex === index)

		if (page) {
			MetricService.addMetricVariables({
				"fp-page-id": page.id,
				"fp-page-type": page.type
			})
		}
	}

	const retrievePageNameFromPageListing = (pageListing?: Page[]) => {
		try {
			if (!pageListing?.length) {
				return null
			}

			const pageNames: string[] = []

			pageListing?.forEach(page => {
				page?.sections?.forEach(section => {
					if (section?.file === "Profile1") {
						const profileSectionContent = section?.content as Profile1Props["content"]

						const pageName = profileSectionContent?.page_name

						if (pageName) {
							pageNames.push(pageName)
						}
					}
				})
			})

			return pageNames[0]
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}

	const getCurrentSitePageIdFromUrl = () => {
		const pageSlug = getQueryParam("pageSlug")

		if (!pageSlug) {
			return null
		}

		const sitePageId = +pageSlug

		const isValidSitePageId = !isNaN(sitePageId)

		window.history.replaceState(null, "", window.location.pathname)

		if (!isValidSitePageId) {
			return null
		}

		return sitePageId
	}

	const getData = async () => {
		try {
			const response = await ApiService.get("/site/fp", {
				params: {
					siteSlug
				}
			})

			const sitePages = response?.data?.pages as Page[]

			setPages(sitePages)
			setSiteSettings(response.data.siteSettings)
			setSiteIntegrations(response.data.siteIntegrations)

			const currentSitePageId = getCurrentSitePageIdFromUrl()
			let initialSlideIndex = 0

			if (currentSitePageId) {
				const currentSitePageIndex = sitePages?.findIndex(page => page.id === currentSitePageId)

				if (currentSitePageIndex !== -1) {
					initialSlideIndex = currentSitePageIndex
				}
			}

			const siteData = response.data?.siteData as SiteData

			const customerId = +response.data?.extraData?.customerId
			const siteId = +response.data?.extraData?.siteId
			const initialPage = sitePages[initialSlideIndex]

			setExtradata({
				siteId,
				customerId
			})

			globalStateStore.setCustomerData(siteData?.customer)
			globalStateStore.setOrganizationData(siteData?.organization)

			MetricService.addMetricVariables({
				"fp-customer-id": customerId,
				"fp-site-id": siteId,
				"fp-page-id": initialPage?.id,
				"fp-page-type": initialPage?.type
			})

			const pageName = retrievePageNameFromPageListing(sitePages)

			document.title = pageName || siteSlug

			const isThereAnyActivePage = sitePages.length > 0

			if (!isThereAnyActivePage) {
				setSiteError("no-active-pages-found")
			}

			setInitialPageIndex(initialSlideIndex)
		} catch (error) {
			ErrorHandlerService.handle(error)
			setSiteError("not-found")
		}

		setLoading(false)

		MetricService.setupFPMetrics()
	}

	useDidMount(() => {
		getData()
	})

	if (siteError === "not-found") {
		return (
			<SiteError
				title="Ops..."
				description="A página que você tentou acessar não está disponível."
				illustration={siteNotFoundIllustration}
			/>
		)
	}

	if (siteError === "no-active-pages-found") {
		return (
			<SiteError
				title="Ops..."
				description="O site que você tentou acessar não possui páginas ativas."
				illustration={noActivePageFoundIllustration}
			/>
		)
	}

	return (
		<Loading loading={loading}>
			<Grid container className={classes.gridContainer}>
				<StoryCarousel
					onSlideChange={onSlideChange}
					initialSlideIndex={initialPageIndex}
				>
					{pages?.map(page => (
						<PageTemplate
							mode="preview"
							sections={page?.sections}
							pageStyles={page?.styles}
							siteSettings={siteSettings}
							siteIntegrations={siteIntegrations}
							style={{
								height: "100%",
								borderRadius: 0,
								boxShadow: "none"
							}}
							extradata={{
								isUniquePage: pages?.length === 1,
								pageType: page.type,
								pageId: page.id,
								siteId: extradata.siteId,
								customerId: extradata.customerId
							}}
						/>
					))}
				</StoryCarousel>
			</Grid>
		</Loading>
	)
}

export default FP
