import { makeStyles } from "@material-ui/core"

import colors from "@/@fp/styles/colors"

export const HEADER_HEIGHT = 64

const useStyles = makeStyles(theme => ({
	container: {
		height: "100%",
		width: "100%",
		flex: 1,
		backgroundColor: colors.greyScale[11]
	},
	content: {
		position: "relative",
		backgroundColor: theme.palette.background.default,
		[theme.breakpoints.up("sm")]: {
			borderLeft: "1px solid rgba(0, 0, 0, 0.025)",
			borderRight: "1px solid rgba(0, 0, 0, 0.025)"
		}
	}
}))

export default useStyles
