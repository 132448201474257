import axios, { AxiosInstance } from "axios"

import envConfig from "@/config/env"

import StorageService from "@/services/Storage"
import ErrorHandlerService from "@/services/ErrorHandler"

import { getQueryParam } from "@/utils/url"
import { getGlobalVariable, setGlobalVariable } from "@/utils/dom"

type IntercomSettings = {
	app_id?: string
	hide_default_launcher?: boolean
}

type OrganizationData = {
	logo: string
	appHost: string
}

type OrganizationPlans = {
	Professional: {
		name: string
		features: string[]
	}
}

class BumeService {
	private api: AxiosInstance
	private authTokenKey = "bumeToken"
	private authTokenQueryParamKey = "bumeToken"
	private isIntercomAppInitialized = false

	constructor () {
		this.api = axios.create({
			baseURL: "https://api.bume.com"
		})

		this.api.interceptors.request.use(config => {
			const authToken = this.authToken

			if (authToken) {
				config.headers.authorization = `Bearer ${authToken}`
			}

			return config
		})
	}

	login () {
		const authToken = getQueryParam(this.authTokenQueryParamKey)

		if (!authToken) {
			return
		}

		StorageService.set(this.authTokenKey, authToken)
	}

	async startIntercom () {
		try {
			if (envConfig.isDevEnvironment) {
				return
			}

			if (this.isIntercomAppInitialized) {
				return
			}

			const intercomApp = getGlobalVariable("Intercom")

			if (!intercomApp) {
				return
			}

			const intercomSettings = await this.getIntercomSettings()

			setGlobalVariable("intercomSettings", intercomSettings)

			intercomApp("boot", intercomSettings)

			this.isIntercomAppInitialized = true
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	private async getIntercomSettings (): Promise<IntercomSettings> {
		try {
			const response = await this.api.get("/api/me/intercom")

			const intercomSettings = response.data

			return {
				...intercomSettings,
				hide_default_launcher: false
			}
		} catch (error) {
			ErrorHandlerService.handle(error)

			return {
				app_id: "fg3o2zwi",
				hide_default_launcher: true
			}
		}
	}

	private get authToken () {
		const authToken = StorageService.get<string>(this.authTokenKey)

		return authToken
	}
}

export default new BumeService()
