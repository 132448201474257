import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	QRCodeContainer: {
		borderRadius: theme.spacing(1.5),
		border: `${theme.spacing(0.5)}px solid ${theme.palette.primary.main}`,
		backgroundColor: colors.greyScale[11],
		padding: theme.spacing(3)
	},
	QRCodeImage: {
		width: "100%",
		height: "auto",
		marginBottom: theme.spacing(2)
	},
	QRCodeText: {
		maxWidth: "100%",
		wordBreak: "break-word"
	},
	shareTitle: {
		color: colors.greyScale[1],
		fontSize: 22,
		lineHeight: "32px",
		fontWeight: 600
	},
	linkShareIcon: {
		color: colors.greyScale[1],
		minWidth: theme.spacing(5)
	},
	linkShareContainer: {
		alignItems: "flex-start"
	},
	linkShareTextContainer: {
		margin: 0
	},
	linkShareTextTitle: {
		fontWeight: 700
	},
	linkShareTextDescription: {
		color: colors.greyScale[1],
		marginTop: theme.spacing(1)
	}
}))

export default useStyles
