import React from "react"
import { Grid, IconButton } from "@material-ui/core"
import {
	ArrowBackIosOutlined
} from "@material-ui/icons"

import useStyles from "@/components/Header/styles"

type HeaderProps = {
	onBackButtonClick: () => void
	style?: React.CSSProperties
	backButtonStyle?: React.CSSProperties
}

const Header: React.FC<HeaderProps> = (props) => {
	const {
		onBackButtonClick,
		children,
		style,
		backButtonStyle
	} = props

	const classes = useStyles()

	const handleBackButtonClick = () => {
		onBackButtonClick()
	}

	return (
		<Grid
			className={classes.container}
			container
			justify="flex-start"
			alignItems="center"
			style={style}
		>
			<IconButton
				className={classes.backButton}
				onClick={handleBackButtonClick}
				style={backButtonStyle}
			>
				<ArrowBackIosOutlined />
			</IconButton>

			{children}
		</Grid>
	)
}

export default Header
