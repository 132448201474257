const entryJoinParam = ":"
const paramJoinParam = "|"

/**
 * That is a way used to compare JSONs to be equal by its content
 * and not by its pointer.
 * Ex:
 * - { abc: 123, bcd: 1235 } becomes "abc:123|bcd:1235"
 * - { bcd: 1235, abc: 123 } becomes "abc:123|bcd:1235"
 * - So we can make sure the data above is the same.
 */
// eslint-disable-next-line
export const serializeJSON = (json: any) => {
	const entries = Object.entries(json || {})

	const params = entries
		?.sort((a, b) => {
			// eslint-disable-next-line
			const aKey = a[0] as any
			// eslint-disable-next-line
			const bKey = b[0] as any

			return aKey - bKey
		})
		?.map(entry => {
			const [key, value] = entry

			return `${key}${entryJoinParam}${value}`
		})

	const serializedJSON = params.join(paramJoinParam)

	return serializedJSON
}

/**
 *
 * @param serializedJSON The serialized JSON created with the 'serializeJSON' method.
 * Ex:
 *  - "abc:123|bcd:1235" becomes { abc: 123, bcd: 1235 }
 */
export function deserializeJSON<Result extends {}> (serializedJSON: string): Result {
	// eslint-disable-next-line
	const json = {} as any

	if (!serializedJSON) {
		return json
	}

	const params = serializedJSON.split(paramJoinParam)

	params.forEach(param => {
		const [key, value] = param.split(entryJoinParam)

		json[key] = value
	})

	return json
}
