import React from "react"
import {
	Fab,
	IconButton
} from "@material-ui/core"
import {
	AddOutlined as AddIcon,
	DeleteOutlined as DeleteIcon,
	SettingsOutlined as SettingsIcon
} from "@material-ui/icons"

import useStyles from "@/components/ActionButton/styles"

type Action = "deleteItem" | "settings" | "addItem"

type ActionButtonProps = {
	action: Action
	onClick?: () => void
	style?: React.CSSProperties
	className?: string
}

type ActionButtonMap = {
	[key in Action]: JSX.Element
}

const ActionButton: React.FC<ActionButtonProps> = (props) => {
	const { action, style, className, onClick } = props

	const classes = useStyles()

	const actionButtonMap: ActionButtonMap = {
		addItem: (
			<Fab
				className={classes.addItemFab}
				size="small"
			>
				<AddIcon
					className={classes.addItemIcon}
				/>
			</Fab>
		),
		deleteItem: (
			<IconButton>
				<DeleteIcon
					fontSize="small"
				/>
			</IconButton>
		),
		settings: (
			<Fab
				size="medium"
			>
				<SettingsIcon
					color="primary"
					fontSize="large"
				/>
			</Fab>
		)
	}

	// eslint-disable-next-line
	const actionButton = actionButtonMap[action as Action] as any

	if (!actionButton) {
		return null
	}

	return React.cloneElement(actionButton, {
		...(onClick ? { onClick } : {}),
		...(style ? { style } : {}),
		...(className ? { className } : {})
	})
}

export default ActionButton
