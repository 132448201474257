import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	paper: {
		borderRadius: theme.spacing(1)
	},
	dialogContainer: {
		position: "relative",
		padding: theme.spacing(3)
	},
	cancelButton: {
		color: colors.greyScale[3]
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	title: {
		color: "#303031",
		fontSize: 26
	},
	description: {
		color: "#303031"
	}
}))

export default useStyles
