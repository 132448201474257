export const mountFPUrl = (slug: string) => {
	const baseUrl = window.origin

	const FPUrl = `${baseUrl}/${slug}`

	return FPUrl
}

export const getQueryParam = (queryParamKey: string) => {
	const queryParams = window.location.search

	const urlSearchParams = new URLSearchParams(queryParams)

	const selectedParam = urlSearchParams.get(queryParamKey)

	return selectedParam
}

export const mountWhatsappUrl = (whatsappNumber: number | string, message?: string) => {
	if (!whatsappNumber) {
		return ""
	}

	const sanitizedNumber = +whatsappNumber.toString().replace(/(\D)/g, "")

	const whatsappUrl = `https://api.whatsapp.com/send?phone=${sanitizedNumber}${message ? `&text=${message}` : ""}`

	return whatsappUrl
}

export const mountEmailUrl = (email: string) => {
	if (!email) {
		return ""
	}

	const emailUrl = `mailto:${email}`

	return emailUrl
}

export const mountPhoneUrl = (phoneNumber: number | string) => {
	if (!phoneNumber) {
		return ""
	}

	const sanitizedNumber = +phoneNumber.toString().replace(/(\D)/g, "")

	const phoneUrl = `tel:+${sanitizedNumber}`

	return phoneUrl
}

export const retrieveSubdomain = () => {
	const [subdomain] = window.location.hostname.split(".")

	return subdomain
}

export const mountRawUrl = (rawUrl: string) => {
	return rawUrl
}

export const replaceSlugInUrlTemplate = (urlTemplate: string, slug: string): string => {
	const result = urlTemplate.replace("<siteSlug>", slug)

	return result
}
