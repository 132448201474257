import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => {
	/**
	 * Workaround to add color to icon when using custom svg
	 * instead of Material Icons.
	 */
	const rawSvgStyles = () => {
		// eslint-disable-next-line
		const materialIconButtonStyles = theme.overrides?.MuiIconButton?.root as any

		return {
			"& path": {
				fill: materialIconButtonStyles?.color
			}
		}
	}

	return {
		iconButton: {
			margin: `1px ${theme.spacing(1)}px`,
			height: 32,
			width: 32,
			padding: 0,
			...rawSvgStyles()
		}
	}
})

export default useStyles
