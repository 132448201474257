import React from "react"
import { Grid, Typography } from "@material-ui/core"

import { Divider } from "@/components"

import useStyles from "@/pages/FP/SiteError/styles"

export { default as siteNotFoundIllustration } from "@/assets/not_found_mockup.png"
export { default as noActivePageFoundIllustration } from "@/assets/no_active_page_found.svg"

type SiteErrorProps = {
	title: string
	description: string
	illustration: string
}

const SiteError: React.FC<SiteErrorProps> = (props) => {
	const { title, description, illustration } = props

	const classes = useStyles()

	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			className={classes.gridContainer}
		>
			<Divider orientation="horizontal" size={2} />

			<Typography variant="h3">
				{title}
			</Typography>

			<Divider orientation="horizontal" size={2} />

			<Typography
				variant="body1"
				align="center"
				className={classes.description}
			>
				{description}
			</Typography>

			<Divider orientation="horizontal" size={6} />

			{illustration && (
				<img
					src={illustration}
					alt="Erro ao abrir página"
					className={classes.image}
				/>
			)}
		</Grid>
	)
}

export default SiteError
