import HTMLToCanvas from "html2canvas"

import ErrorHandlerService from "@/services/ErrorHandler"

type HTMLToImageOptions = {
	quality?: number
	backgroundColor?: string
	scale?: number
	format?: "png" | "jpeg"
}

class ImageService {
	async HTMLToImage (element: HTMLElement, options?: HTMLToImageOptions): Promise<string | null> {
		try {
			const canvas = await HTMLToCanvas(element, {
				backgroundColor: options?.backgroundColor,
				scale: options?.scale
			})

			const url = canvas.toDataURL(`image/${options?.format || "png"}`, options?.quality)

			return url
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}

	downloadImageByUrl (fileName: string, url: string): void {
		const linkElement = document.createElement("a")

		linkElement.href = url
		linkElement.download = fileName
		linkElement.click()
	}
}

export default new ImageService()
