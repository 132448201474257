import { Theme } from "@material-ui/core"
import { Route } from "react-router-dom"
import React from "react"

import { useGlobalStateStore } from "@/store/GlobalState"

import { injectJS, setFavicon } from "@/utils/dom"
import { replaceSlugInUrlTemplate } from "@/utils/url"
import { loadFonts } from "@/utils/css"

import bumeBrandLogoImg from "@/assets/brands/bume.png"
import bumeFaviconImg from "@/assets/favicons/bume.png"

import envConfig from "@/config/env"
import appConfig from "@/config/app"

import { Color } from "@/protocols/color"

import defaultTheme from "@/styles/theme"
import defaultColors from "@/styles/colors"

import LastlinkTheme from "@/@organizations/LastLink/styles/theme"
import LastlinkColors from "@/@organizations/LastLink/styles/colors"
import { LastlinkPublicCPRoutes } from "@/@organizations/LastLink/routes"

import { OrganizationType, Organization } from "@/protocols/organization"

import ErrorHandlerService from "@/services/ErrorHandler"

type OrganizationPlans = {
	Professional: {
		name: string
		features: string[]
	}
}

type Routes = {
	public: {
		cp: React.ReactElement | null
	}
}

type OrganizationData = {
	fpLogo: string
	headerLogo: string
	appHost: string
	landingPageUrl: string
	name: string
	changeSlugHost: string
	type: OrganizationType
	signInRoute: string
	signUpRoute: string
	menuItems: Organization["config"]["menuItems"]
	faviconUrl: string
	cpHost: string
	bannerImageUrl: string
	bannerRedirectUrl: string
	bannerPaddingBottom: string
}

const DEFAULT_ORGANIZATION_DATA: OrganizationData = {
	fpLogo: bumeBrandLogoImg,
	headerLogo: bumeBrandLogoImg,
	appHost: "bumesite.com",
	cpHost: "app.bumesite.com",
	landingPageUrl: "https://bume.com",
	name: "Bume",
	changeSlugHost: "<siteSlug>.bumesite.com",
	type: appConfig.appOrganizationType,
	signInRoute: "/lastlink/signin",
	signUpRoute: "/lastlink/signup",
	menuItems: [
		{ type: "link", title: "Indique e ganhe", value: "#" },
		{ type: "link", title: "Blog", value: "https://blog.lastlink.com" },
		{ type: "link", title: "Vender conteúdo", value: "https://lastlink.com" }
	],
	faviconUrl: bumeFaviconImg,
	bannerImageUrl: "",
	bannerRedirectUrl: "",
	bannerPaddingBottom: ""
}

const useOrganization = () => {
	const globalStateStore = useGlobalStateStore()

	const buildOrganizationSelectorName = (value: string) => {
		const organizationSelectorName = `${globalStateStore.organization?.name}@${value}`

		return organizationSelectorName
	}

	const isCPUrl = () => {
		const CPHost = globalStateStore?.organization?.config?.url?.cp || DEFAULT_ORGANIZATION_DATA.cpHost

		const isCPUrl = window.location.href.includes(CPHost)

		return isCPUrl
	}

	const setupPage = () => {
		if (globalStateStore?.organization?.name) {
			document.title = `${globalStateStore?.organization?.name} - Meu Site`
		}

		setFavicon(globalStateStore?.organization?.config?.favicon || DEFAULT_ORGANIZATION_DATA.faviconUrl)
	}

	const enablePlugins = (): void => {
		try {
			if (envConfig.isDevEnvironment) {
				return
			}

			const integrationCodes = globalStateStore.organization?.config?.integrationCodes

			const hotjarIntegrationCode = isCPUrl() ? (
				integrationCodes?.cp?.hotjar
			) : (
				integrationCodes?.fp?.hotjar
			)

			if (hotjarIntegrationCode) {
				const hotjarCode = `
					;(function (h, o, t, j, a, r) {
						h.hj =
							h.hj ||
							function () {
								;(h.hj.q = h.hj.q || []).push(arguments)
							}
						h._hjSettings = { hjid: ${hotjarIntegrationCode}, hjsv: 6 }
						a = o.getElementsByTagName("head")[0]
						r = o.createElement("script")
						r.async = 1
						r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
						a.appendChild(r)
					})(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
				`

				injectJS(hotjarCode, buildOrganizationSelectorName("hotjar"))
			}

			const googleTagManagerIntegrationCode = isCPUrl() ? (
				integrationCodes?.cp?.googleTagManager
			) : (
				integrationCodes?.fp?.googleTagManager
			)

			if (googleTagManagerIntegrationCode) {
				const googleTagManagerCode = `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${googleTagManagerIntegrationCode}');
				`

				injectJS(googleTagManagerCode, buildOrganizationSelectorName("google-tag-manager"))
			}
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const mountPlanPageUrl = (slug: string): string => {
		if (!slug) {
			return ""
		}

		const planPageUrl = replaceSlugInUrlTemplate(globalStateStore?.organization?.config?.url?.plan, slug)

		return planPageUrl
	}

	const mountDashboardPageUrl = (slug: string): string => {
		if (!slug) {
			return ""
		}

		const planPageUrl = replaceSlugInUrlTemplate(globalStateStore?.organization?.config?.url?.dashboard, slug)

		return planPageUrl
	}

	const plans: OrganizationPlans = {
		Professional: {
			name: "Profissional",
			features: [
				"Agendamento para redes sociais",
				"Meu site",
				"Captura de Leads com até 5 mil contatos",
				"Relatórios",
				"Respostas automáticas",
				"Suporte online"
			]
		}
	}

	const data: OrganizationData = {
		fpLogo: globalStateStore?.organization?.config?.logo?.fp || DEFAULT_ORGANIZATION_DATA.fpLogo,
		headerLogo: globalStateStore?.organization?.config?.logo?.header || DEFAULT_ORGANIZATION_DATA.headerLogo,
		appHost: globalStateStore?.organization?.config?.host?.fp || DEFAULT_ORGANIZATION_DATA.appHost,
		landingPageUrl: globalStateStore?.organization?.config?.url?.landingPage || DEFAULT_ORGANIZATION_DATA.landingPageUrl,
		name: globalStateStore?.organization?.name || DEFAULT_ORGANIZATION_DATA.name,
		changeSlugHost: globalStateStore?.organization?.config?.host?.changeSlug || DEFAULT_ORGANIZATION_DATA.changeSlugHost,
		type: globalStateStore?.organization?.type || DEFAULT_ORGANIZATION_DATA.type,
		signInRoute: globalStateStore?.organization?.config?.route?.signIn || DEFAULT_ORGANIZATION_DATA.signInRoute,
		signUpRoute: globalStateStore?.organization?.config?.route?.signUp || DEFAULT_ORGANIZATION_DATA.signUpRoute,
		menuItems: globalStateStore?.organization?.config?.menuItems || DEFAULT_ORGANIZATION_DATA.menuItems,
		cpHost: globalStateStore?.organization?.config?.url?.cp,
		faviconUrl: globalStateStore?.organization?.config?.favicon,
		bannerImageUrl: globalStateStore?.organization?.config?.banner?.image,
		bannerRedirectUrl: globalStateStore?.organization?.config?.banner?.href,
		bannerPaddingBottom: globalStateStore?.organization?.config?.banner?.paddingBottom
	}

	const getTheme = () => {
		const themeMap: Record<OrganizationType | "default", Theme> = {
			lastlink: LastlinkTheme,
			default: defaultTheme
		}

		const selectedTheme = themeMap[data.type] || themeMap.default

		return selectedTheme
	}

	const getColors = () => {
		const colorsMap: Record<OrganizationType | "default", Color> = {
			lastlink: LastlinkColors,
			default: defaultColors
		}

		const selectedColor = colorsMap[data.type] || colorsMap.default

		return selectedColor
	}

	const theme = getTheme()
	const colors = getColors()

	const setupFonts = () => {
		if (globalStateStore?.organization?.config?.fontFamilies?.length) {
			const cssID = buildOrganizationSelectorName("fonts")
			const fontFamilies = globalStateStore?.organization?.config?.fontFamilies

			loadFonts(cssID, fontFamilies)
		}

		if (theme.typography.fontFamily) {
			const stylesheetFontFamily = theme.typography.fontFamily
			const stylesheetFontCssID = buildOrganizationSelectorName("stylesheet-font")

			loadFonts(stylesheetFontCssID, [stylesheetFontFamily])
		}
	}

	const setup = () => {
		enablePlugins()
		setupPage()
		setupFonts()
	}

	const getRoutes = () => {
		const routesMap: Record<OrganizationType | "default", Routes> = {
			lastlink: {
				public: {
					cp: (
						<Route
							key="lastlink"
							path="/lastlink"
							component={LastlinkPublicCPRoutes}
						/>
					)
				}
			},
			default: {
				public: {
					cp: null
				}
			}
		}

		const selectedRoutes = routesMap[data.type] || routesMap.default

		return selectedRoutes
	}

	const routes = getRoutes()

	return {
		mountPlanPageUrl,
		mountDashboardPageUrl,
		plans,
		data,
		setup,
		theme,
		colors,
		routes
	}
}

export default useOrganization
