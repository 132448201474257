import React, { useState } from "react"
import {
	Typography,
	FormControlLabel,
	Switch,
	Grid,
	Fab,
	Button,
	Divider as MaterialDivider
} from "@material-ui/core"
import {
	SettingsOutlined as SettingsOutlinedIcon
} from "@material-ui/icons"
import { useParams, useHistory } from "react-router-dom"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import TemplateService from "@/services/Template"
import PageService from "@/services/Page"

import { TemplatePageData } from "@/@fp/protocols/Page"
import { TemplatePage } from "@/protocols/page"

import {
	Divider,
	Loading,
	PopConfirm,
	EditDrawer,
	PageCard,
	ImageSelect
} from "@/components"

import useStyles from "@/pages/PageList/PageEdit/PageSettingsEditDrawer/styles"
import useCustomStyles from "@/styles/custom"
import useDidMount from "@/hooks/useDidMount"

import PageSettingsTemplatePageListSkeleton from "@/skeletons/PageSettingsTemplatePageList"
import PageSettingsPageDataSkeleton from "@/skeletons/PageSettingsPageData"

type PageSettingsEditDrawerProps = {
	onTemplatePageSave?: () => (Promise<void> | void)
}

const PageSettingsEditDrawer: React.FC<PageSettingsEditDrawerProps> = (props) => {
	const {
		onTemplatePageSave
	} = props

	const classes = useStyles()
	const customClasses = useCustomStyles()
	const history = useHistory()

	const [loadingTemplatePages, setLoadingTemplatePages] = useState(true)
	const [loadingPageData, setLoadingPageData] = useState(true)

	const [active, setActive] = useState<boolean>(true)
	const [templateData, setTemplateData] = useState<TemplatePageData>({} as TemplatePageData)
	const [templatePages, setTemplatePages] = useState<TemplatePage[]>([])

	const { pageId, siteId } = useParams<{ pageId: string, siteId: string }>()

	const getTemplatePages = async (templatePageCategoryId?: number) => {
		if (!templatePageCategoryId) {
			return null
		}

		const wasTemplatePagesLoaded = templatePages?.length > 0

		if (!wasTemplatePagesLoaded) {
			const templatePages = await TemplateService.getTemplatePagesByCategoryId(
				templatePageCategoryId
			)

			if (templatePages) {
				setTemplatePages(templatePages)
			}
		}

		setLoadingTemplatePages(false)
	}

	const getPageData = async () => {
		const pageData = await PageService.getPageData(+pageId)

		if (pageData) {
			setActive(pageData.active)
			setTemplateData(pageData.templateData)
			getTemplatePages(pageData.templateData?.templatePageCategoryId)
		}

		setLoadingPageData(false)
	}

	const handleDeletePage = async () => {
		PopConfirm.open({
			description: "Deseja excluir esta página?",
			confirmButtonText: "Excluir",
			onConfirm: async () => {
				try {
					await ApiService.delete("/site/pages", {
						params: {
							pageId
						}
					})
				} catch (error) {
					ErrorHandlerService.handle(error)
				}

				history.push(`/sites/${siteId}/pages`)
			}
		})
	}

	const handleTogglePageActive = async (active: boolean) => {
		const lastActiveValue = active

		setActive(active)

		try {
			await ApiService.put(`/site/${siteId}/pages/${pageId}/active`, {
				active
			})
		} catch (error) {
			ErrorHandlerService.handle(error)
			setActive(lastActiveValue)
		}
	}

	const handleChangeTemplate = (templatePageId: number) => {
		setTemplateData(lastState => ({
			...lastState,
			templatePageId
		}))
	}

	const handleSaveTemplatePage = async (): Promise<boolean> => {
		const { templatePageId } = templateData

		try {
			await ApiService.put(`/site/pages/${pageId}/templates/${templatePageId}`)

			if (onTemplatePageSave) {
				await onTemplatePageSave()
			}

			setTemplateData(lastState => {
				const newTemplatePage = templatePages
					.find(templatePage => templatePage.id === templatePageId)

				return {
					...lastState,
					coverUrl: newTemplatePage?.coverUrl || lastState.coverUrl
				}
			})

			return true
		} catch (error) {
			ErrorHandlerService.handle(error)
			return false
		}
	}

	const getData = async () => {
		await getPageData()
	}

	useDidMount(() => {
		getData()
	})

	return (
		<EditDrawer
			title="Configurar página"
			description="Defina as configurações referentes a sua página."
			formComponent={(
				<Grid
					container
					spacing={2}
				>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Loading loading={loadingPageData} customLoadingElement={<PageSettingsPageDataSkeleton />}>
							<Grid
								container
								justify="space-between"
								alignItems="center"
							>
								<FormControlLabel
									className={classes.formControlLabel}
									control={(
										<Switch
											color="primary"
											checked={active}
											onChange={(_, active) => handleTogglePageActive(active)}
											name="active"
											className={classes.formControlSwitch}
										/>
									)}
									label={(
										<>
											<Typography
												variant="body1"
												className={customClasses.toggleTitle}
											>
												{active ? "Página ativa" : "Página inativa"}
											</Typography>

											<Divider orientation="horizontal" size={1} />

											<Typography
												className={classes.toggleDescription}
												variant="caption"
											>
												Ao desativar sua página, ela não estará mais visível para seus visitantes.
											</Typography>
										</>
									)}
									classes={{
										label: classes.formControlText
									}}
								/>
							</Grid>
						</Loading>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<MaterialDivider />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography
							variant="button"
						>
							TEMA
						</Typography>

						<Divider orientation="horizontal" size={2} />

						<Grid
							container
							className={classes.templatePageContainer}
						>
							<EditDrawer
								title="Alterar tema"
								description="Escolha o tema que faz mais sentido para o seu conteúdo."
								onSave={handleSaveTemplatePage}
								formComponent={(
									<Loading loading={loadingTemplatePages} customLoadingElement={<PageSettingsTemplatePageListSkeleton />}>
										<ImageSelect
											value={templateData.templatePageId}
											items={(
												templatePages.map(templatePage => ({
													imageSrc: templatePage.coverUrl,
													value: templatePage.id
												}))
											)}
											onChange={handleChangeTemplate}
										/>
									</Loading>
								)}
								pureChildren
							>
								<PageCard
									backgroundPictureSrc={templateData.coverUrl}
									elevation={0}
								/>
							</EditDrawer>
						</Grid>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<MaterialDivider />
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Button
							variant="text"
							color="primary"
							onClick={handleDeletePage}
							className={classes.deletePageButton}
						>
							EXCLUIR PÁGINA
						</Button>
					</Grid>
				</Grid>
			)}
			pureChildren
			hideSaveButton
		>
			<Fab
				size="medium"
			>
				<SettingsOutlinedIcon
					color="primary"
					fontSize="large"
				/>
			</Fab>
		</EditDrawer>
	)
}

export default PageSettingsEditDrawer
