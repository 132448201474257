import React from "react"
import {
	Card,
	CardProps,
	CircularProgress,
	Grid
} from "@material-ui/core"

import useStyles from "./styles"

type PortletProps = CardProps & {
	elevation?: number
	loading?: boolean
	style?: React.CSSProperties
	button?: boolean
}

const Portlet: React.FC<PortletProps> = (props) => {
	const { children, loading, style, className, ...rest } = props

	const classes = useStyles({ loading })

	if (loading) {
		return (
			<Card
				className={`${classes.card} ${className}`}
				elevation={props.elevation || 2}
				style={{
					...style,
					pointerEvents: "none"
				}}
				{...rest}
			>
				<Grid
					container
					className={classes.cardLoadingContainer}
				>
					<CircularProgress />
				</Grid>

				{children}
			</Card>
		)
	}

	return (
		<Card
			className={classes.card}
			elevation={props.elevation || 2}
			style={{ ...style }}
			{...rest}
		>
			{children}
		</Card>
	)
}

export default Portlet
