import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	templateLoading: {
		position: "absolute",
		zIndex: 9,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(255, 255, 255, 0.7)"
	},
	templateContainer: {
		position: "relative"
	}
})

export default useStyles
