import React, { useState } from "react"
import {
	Dialog as MaterialDialog,
	Grid,
	Typography,
	Button,
	ThemeProvider,
	IconButton
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { renderComponent } from "@/utils/node"
import { isMobile } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"
import useOrganization from "@/hooks/useOrganization"

import { Divider } from "@/components"

import useStyles from "@/@organizations/LastLink/pages/Setup/Dialog/styles"

type DialogProps = {}

type DialogType = {
	open: (props: DialogProps) => void
}

const Dialog: DialogType & React.FC<DialogProps> = () => {
	const classes = useStyles()
	const organization = useOrganization()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	useDidMount(() => {
		if (isMobile) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	return (
		<ThemeProvider
			theme={organization.theme}
		>
			<MaterialDialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
			>
				<Grid
					container
					className={classes.dialogContainer}
				>
					<IconButton onClick={handleClose} className={classes.closeIcon}>
						<CloseIcon />
					</IconButton>

					<Typography
						variant="h3"
						className={classes.title}
					>
						Lastlink
					</Typography>

					<Divider orientation="horizontal" size={5} />

					<Typography
						variant="body1"
						className={classes.description}
					>
						Você será redirecionado para nossa plataforma de criadores de conteúdo.
					</Typography>

					<Divider orientation="horizontal" size={6} />

					<Grid
						container
						justify="center"
					>
						<Button
							variant="contained"
							color="primary"
							href="https://lastlink.com"
							onClick={handleClose}
							fullWidth
							disableElevation
							{...({ target: "_self" })}
						>
							Vamos nessa
						</Button>
					</Grid>
				</Grid>
			</MaterialDialog>
		</ThemeProvider>
	)
}

Dialog.open = (props: DialogProps) => {
	renderComponent(
		"simple-dialog",
		<Dialog
			{...props}
		/>
	)
}

export default Dialog
