import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	selectedButton: {
		border: "none"
	},
	selectedIcon: {
		width: 22,
		height: 22,
		minHeight: 22,
		"& svg": {
			height: 18,
			width: 18
		}
	},
	buttonContainer: {
		flexBasis: "33%",
		"& img": {
			borderRadius: "0px"
		}
	}
})

export default useStyles
