import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Portlet, Divider, PageCard } from "@/components"

const TemplatePageCategoryListSkeleton = () => {
	const templatePageCategories = []

	const totalItems = 1

	// eslint-disable-next-line
	for (let i = 0; i < totalItems; i++) {
		templatePageCategories.push(
			<Grid key={i} item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Portlet
					elevation={1}
					style={{
						border: "1px solid #DDE2E5"
					}}
				>
					<Grid container spacing={2}>
						<Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
							<PageCard
								elevation={0}
								style={{
									border: "0.7px solid #DDE2E5"
								}}
							>
								<Skeleton variant="rect" width="100%" height="100%" animation="wave" />
							</PageCard>
						</Grid>

						<Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container direction="row">
										<Skeleton variant="circle" width={40} height={40} animation="wave" />

										<Divider orientation="vertical" size={1} />

										<Skeleton variant="rect" width="50%" height={40} animation="wave" />
									</Grid>
								</Grid>

								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Skeleton variant="rect" width="100%" height={25} animation="wave" />

									<Divider orientation="horizontal" size={1} />

									<Skeleton variant="rect" width="80%" height={25} animation="wave" />

									<Divider orientation="horizontal" size={1} />

									<Skeleton variant="rect" width="50%" height={25} animation="wave" />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Portlet>
			</Grid>
		)
	}

	return (
		<Grid container spacing={2}>
			{templatePageCategories}
		</Grid>
	)
}

export default TemplatePageCategoryListSkeleton
