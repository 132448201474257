import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		height: 88,
		backgroundColor: colors.greyScale[11],
		width: "100%",
		padding: theme.spacing(2)
	},
	backButton: {
		color: colors.greyScale[1]
	}
}))

export default useStyles
