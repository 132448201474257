import React, { useRef } from "react"
import { Grid } from "@material-ui/core"

import ImageService from "@/services/Image"

type WrapChildrenProps = {
	containerWidth?: number
	containerHeight?: number
}

type UseChildrenImageResponse = {
	getChildrenImage: () => Promise<string | null>
	wrapChildren: (children: React.ReactNode, props?: WrapChildrenProps) => JSX.Element
}

const useChildrenImage = (): UseChildrenImageResponse => {
	const childrenRef = useRef<HTMLElement>()

	const getChildrenImage = async (): Promise<string | null> => {
		const childrenElement = childrenRef.current as HTMLElement

		const childrenImageUrl = await ImageService.HTMLToImage(childrenElement, {
			quality: 1,
			backgroundColor: "transparent",
			scale: 2,
			format: "png"
		})

		return childrenImageUrl
	}

	const wrapChildren = (children: React.ReactNode, props?: WrapChildrenProps): JSX.Element => (
		<>
			<Grid>
				{children}
			</Grid>

			<Grid
				innerRef={childrenRef}
				style={{
					width: props?.containerWidth || 1000,
					height: props?.containerHeight,
					position: "fixed",
					top: 0,
					right: "100%",
					zIndex: -999
				}}
			>
				{children}
			</Grid>
		</>
	)

	return {
		getChildrenImage,
		wrapChildren
	}
}

export default useChildrenImage
