import React, { useState, cloneElement } from "react"
import {
	CircularProgress,
	Button,
	Drawer,
	ButtonBase,
	Typography,
	Grid,
	ThemeProvider
} from "@material-ui/core"
import { Prompt } from "react-router-dom"

import {
	Header,
	Divider,
	SectionPlaceholderText,
	SuccessModal
} from "@/components"

import { handleBackButton } from "@/utils/backButton"
import { isMobile } from "@/utils/checkDevice"

import { useSectionStateStore } from "@/@fp/store/SectionState"
import useOrganization from "@/hooks/useOrganization"

import useStyles from "@/components/EditDrawer/styles"

type EditDrawerProps = {
	onSave?: () => Promise<boolean>
	onOpen?: () => void
	title: string
	description?: string
	formComponent: React.ReactNode | (() => React.ReactNode)
	placeholderText?: string
	showPlaceholder?: boolean
	pureChildren?: boolean
	hideSaveButton?: boolean
	saveButtonText?: string
	childrenContainerStyle?: React.CSSProperties
}

const EditDrawer: React.FC<EditDrawerProps> = (props) => {
	const {
		children,
		onSave,
		onOpen,
		title,
		formComponent,
		description,
		placeholderText,
		showPlaceholder,
		pureChildren,
		hideSaveButton,
		childrenContainerStyle,
		saveButtonText
	} = props

	const [loading, setLoading] = useState(false)
	const [opened, setOpened] = useState(false)

	const organization = useOrganization()

	const sectionStateStore = useSectionStateStore()
	const classes = useStyles({
		topSpacing: sectionStateStore?.sectionDefaultLayout?.firstSectionTopSpacing || 0,
		lateralSpacing: sectionStateStore?.sectionDefaultLayout?.sectionLateralSpacing
	})

	const handleClose = () => {
		setOpened(false)
	}

	const handleOpen = () => {
		if (onOpen) {
			onOpen()
		}

		setOpened(true)

		if (isMobile) {
			handleBackButton(
				() => handleClose()
			)
		}
	}

	const handleSave = async () => {
		if (!onSave) {
			return handleClose()
		}

		setLoading(true)

		const result = await onSave()

		setLoading(false)

		if (result === true) {
			handleClose()

			SuccessModal.open({})
		}
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()

		handleSave()
	}

	/**
	 *	In case the user tries to push a state to the same pathname we prevent him from
	 *	changing state. That's a workaround since some child can be LazyLoaded and it
	 * 	reloads after every state push, even if it is the same path being used.
	 */
	const handleRouteChange = (expectedLocation: Location): boolean => {
		let canChangeRoute = true

		const expectedPathname = expectedLocation.pathname
		const currentPathname = window.location.pathname

		if (!isMobile) {
			canChangeRoute = true
		} else if (!opened) {
			canChangeRoute = true
		} else if (expectedPathname === currentPathname) {
			canChangeRoute = false
		}

		return canChangeRoute
	}

	const handleFormComponentRender = () => {
		if (typeof formComponent === "function") {
			return formComponent()
		} else {
			return formComponent
		}
	}

	return (
		<>
			<Prompt
				// eslint-disable-next-line
				message={(expectedLocation) => handleRouteChange(expectedLocation as any)}
			/>

			{children && (
				<>
					{pureChildren ? (
						cloneElement(children as JSX.Element, {
							onClick: handleOpen
						})
					) : (
						<Grid
							container
							component={ButtonBase}
							onClick={handleOpen}
							className={classes.dashedBorder}
						>
							{showPlaceholder ? (
								<SectionPlaceholderText
									text={placeholderText || ""}
								/>
							) : (
								<Grid
									container
									className={classes.childrenContainer}
									style={childrenContainerStyle}
								>
									{children}
								</Grid>
							)}
						</Grid>
					)}
				</>
			)}

			<ThemeProvider
				theme={organization.theme}
			>
				<Drawer
					anchor="right"
					open={opened}
					onClose={handleClose}
					classes={{ paper: classes.drawerPaper }}
					style={{ width: "100vw" }}
				>
					<Grid container>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container>
								<Header
									onBackButtonClick={handleClose}
								>
									<Divider orientation="vertical" size={1} />

									<Typography
										className={classes.drawerTitle}
										variant="h4"
									>
										{title}
									</Typography>
								</Header>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid
								container
								component="form"
								onSubmit={handleSubmit}
								className={classes.drawerContainer}
							>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid
										container
										className={classes.drawerContent}
									>
										{description && (
											<>
												<Typography
													variant="body1"
													className={classes.drawerDescription}
												>
													{description}
												</Typography>

												<Divider orientation="horizontal" size={3} />
											</>
										)}

										{handleFormComponentRender()}
									</Grid>
								</Grid>

								{!hideSaveButton && (
									<Grid
										container
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
										className={classes.saveButtonContainer}
									>
										<Button
											endIcon={loading ? (
												<CircularProgress size={20} className={classes.loading} />
											) : (
												null
											)}
											className={classes.saveButton}
											disabled={loading}
											onClick={handleSave}
											color="primary"
											variant="contained"
											fullWidth
											disableElevation
										>
											{saveButtonText || (
												<>
													{loading ? "Salvando" : "Salvar"}
												</>
											)}
										</Button>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Drawer>
			</ThemeProvider>
		</>
	)
}

export default EditDrawer
