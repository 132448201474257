import React from "react"
import { Route, Redirect } from "react-router-dom"

import SignUp from "@/@organizations/LastLink/pages/SignUp"
import SignInFirstStep from "@/@organizations/LastLink/pages/SignInFirstStep"
import SignInLastStep from "@/@organizations/LastLink/pages/SignInLastStep"

import { PrivateCPRoutes } from "@/@organizations/LastLink/routes/private"

export const PublicCPRoutes = [
	<Route
		exact
		key="lastlink-signup"
		path="/lastlink/signup"
		component={SignUp}
	/>,
	<Route
		exact
		key="lastlink-signin"
		path="/lastlink/signin"
		component={() => <Redirect to="/lastlink/signin/first-step" />}
	/>,
	<Route
		exact
		key="lastlink-signin-first-step"
		path="/lastlink/signin/first-step"
		component={SignInFirstStep}
	/>,
	<Route
		exact
		key="lastlink-signin-last-step"
		path="/lastlink/signin/last-step"
		component={SignInLastStep}
	/>,
	...PrivateCPRoutes
]
