import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	IconButton,
	Menu,
	MenuItem,
	Link as MaterialLink
} from "@material-ui/core"
import {
	AccountCircleOutlined as AccountIcon
} from "@material-ui/icons"

import AuthService from "@/services/Auth"

import useOrganization from "@/hooks/useOrganization"
import { useGlobalStateStore } from "@/store/GlobalState"
import useStyles, { HEADER_HEIGHT } from "@/components/OrganizationHeader/styles"
import useDidMount from "@/hooks/useDidMount"

import { AppContainer } from "@/components"

import appConfig from "@/config/app"

import bumeLogoImg from "@/assets/brands/bume.png"

import { getQueryParam } from "@/utils/url"

const OrganizationHeader = () => {
	const organization = useOrganization()
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()

	const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null)
	const menuOpened = Boolean(menuAnchorElement)

	const getLastlinkLogo = () => {
		const isSignUpPage = window.location.pathname.includes("signup")

		const origin = getQueryParam("origin")

		const isBumeOrigin = origin === "bume"

		const showBumeLogo = isBumeOrigin && isSignUpPage

		const logoImg = showBumeLogo ? bumeLogoImg : organization?.data.headerLogo

		return logoImg
	}

	const getLogo = () => {
		if (organization.data.type === "lastlink") {
			return getLastlinkLogo()
		} else {
			return organization.data.headerLogo
		}
	}

	const setup = () => {
		const appContainer = document.getElementById(appConfig.appContainerId)

		if (appContainer) {
			appContainer.style.paddingTop = `${HEADER_HEIGHT}px`
		}
	}

	const handleOpenMenu = (targetElement: HTMLElement) => {
		setMenuAnchorElement(targetElement)
	}

	const handleCloseMenu = () => {
		setMenuAnchorElement(null)
	}

	const getMenuIcon = () => {
		return <AccountIcon />
	}

	useDidMount(() => {
		setup()
	})

	return (
		<AppContainer
			id="app-header-container"
			ContainerProps={{
				className: classes.headerContainer
			}}
			ContentProps={{
				className: classes.headerContent
			}}
		>
			<Link
				to="/site/list"
				className={classes.logoImg}
			>
				<img
					src={getLogo()}
					alt="logo"
					className={classes.logoImg}
				/>
			</Link>

			{globalStateStore?.customer?.id && (
				<>
					<IconButton
						className={classes.menuButton}
						onClick={({ target }) => handleOpenMenu(target as HTMLElement)}
					>
						{getMenuIcon()}
					</IconButton>

					<Menu
						anchorEl={menuAnchorElement}
						open={menuOpened}
						onClose={handleCloseMenu}
						classes={{
							paper: classes.menuPaper
						}}
					>
						<MenuItem
							onClick={handleCloseMenu}
							component={Link}
							to="/site/list"
						>
							Início
						</MenuItem>

						<MenuItem
							onClick={handleCloseMenu}
							component={Link}
							to="/customer/account"
						>
							Minha conta
						</MenuItem>

						{organization.data.menuItems.map(menuItem => (
							<MenuItem
								key={menuItem.title}
								onClick={handleCloseMenu}
								{...(menuItem.type === "link" ? {
									component: MaterialLink,
									href: menuItem.value,
									target: "_blank"
								} : {
									component: Link,
									to: menuItem.value
								})}
							>
								{menuItem.title}
							</MenuItem>
						))}

						<MenuItem
							onClick={() => AuthService.logout(organization.data.signInRoute)}
							component={MaterialLink}
						>
							Sair
						</MenuItem>
					</Menu>
				</>
			)}
		</AppContainer>
	)
}

export default OrganizationHeader
