import React, { useImperativeHandle, forwardRef, useState } from "react"
import { Grid } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import useStyles from "@/pages/FP/StoryCarousel/TabBar/styles"

import useDidMount from "@/hooks/useDidMount"

type TabBarProps = {
	initialTabIndex: number
	tabBarCount: number
	onIndexChange: (nextIndex: number) => void
}

export type TabBarHandler = {
	handleChangeTabIndex: (index: number) => void
}

let currentTabIndexCache = 0

const TabBar: React.RefForwardingComponent<TabBarHandler, TabBarProps> = (
	props: TabBarProps,
	ref
) => {
	const {
		initialTabIndex,
		tabBarCount,
		onIndexChange
	} = props

	const [currentTabIndex, setCurrentTabIndex] = useState(initialTabIndex)

	const classes = useStyles({ tabBarCount })

	const handleChangeTabIndex = (index: number) => {
		if (index === currentTabIndexCache) {
			return
		}

		currentTabIndexCache = index

		setCurrentTabIndex(index)

		onIndexChange(index)
	}

	useImperativeHandle(ref, () => ({
		handleChangeTabIndex
	}))

	useDidMount(() => {
		currentTabIndexCache = initialTabIndex
	})

	return (
		<Grid
			container
			alignItems="center"
			justify="space-evenly"
			className={classes.tabContainer}
		>
			{[...new Array(tabBarCount)].map((_, index) => (
				<Grid
					container
					key={index}
					onClick={() => handleChangeTabIndex(index)}
					className={classes.tabBarContainer}
				>
					<Grid
						container
						className={classes.tabBarContent}
					>
						<Skeleton
							key={index}
							variant="rect"
							className={`${index === currentTabIndex && classes.currentTabBar}`}
							animation={false}
						/>
					</Grid>
				</Grid>
			))}
		</Grid>
	)
}

export default forwardRef(TabBar)
