import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	card: {
		width: "100%",
		height: 260,
		backgroundColor: "#FFFFFF",
		borderRadius: 4
	}
})

const PageSettingsTemplatePageListSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
				<Card
					className={classes.card}
				>
					<Skeleton variant="rect" width="100%" height="100%" animation="wave" />
				</Card>
			</Grid>

			<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
				<Card
					className={classes.card}
				>
					<Skeleton variant="rect" width="100%" height="100%" animation="wave" />
				</Card>
			</Grid>
		</Grid>
	)
}

export default PageSettingsTemplatePageListSkeleton
