import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	paragraph: {
		wordBreak: "break-word",
		whiteSpace: "break-spaces"
	}
})

export default useStyles
