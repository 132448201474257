import { Theme, createMuiTheme } from "@material-ui/core"

import { PageStyles } from "@/@fp/protocols/Page"

import defaultTheme from "@/@fp/themes/default"

class PageTheme {
	retrieveBuildedTheme (pageStyles?: PageStyles): Theme {
		if (!pageStyles || !Object.keys(pageStyles).length) {
			return defaultTheme
		}

		const theme = createMuiTheme({
			palette: {
				background: {
					default: pageStyles?.general?.background_default,
					paper: pageStyles?.general?.background_paper
				},
				text: {
					primary: pageStyles?.general?.text_primary_color,
					secondary: pageStyles?.general?.text_secondary_color
				}
			},
			typography: {
				fontFamily: pageStyles?.general?.font_family,
				h1: {
					fontStyle: pageStyles?.typography?.main_title_font_style,
					fontWeight: pageStyles?.typography?.main_title_font_weight,
					fontSize: pageStyles?.typography?.main_title_font_size,
					lineHeight: pageStyles?.typography?.main_title_line_height,
					fontFamily: pageStyles?.typography?.main_title_font_family,
					color: pageStyles?.typography?.main_title_font_color
				},
				h2: {
					fontStyle: pageStyles?.typography?.secondary_title_font_style,
					fontWeight: pageStyles?.typography?.secondary_title_font_weight,
					fontSize: pageStyles?.typography?.secondary_title_font_size,
					lineHeight: pageStyles?.typography?.secondary_title_line_height,
					fontFamily: pageStyles?.typography?.secondary_title_font_family,
					color: pageStyles?.typography?.secondary_title_font_color
				},
				body1: {
					fontStyle: pageStyles?.paragraph?.main_paragraph_font_style,
					fontWeight: pageStyles?.paragraph?.main_paragraph_font_weight,
					fontSize: pageStyles?.paragraph?.main_paragraph_font_size,
					lineHeight: pageStyles?.paragraph?.main_paragraph_line_height,
					fontFamily: pageStyles?.paragraph?.main_paragraph_font_family,
					color: pageStyles?.paragraph?.main_paragraph_font_color
				},
				button: {
					fontStyle: pageStyles?.button?.primary_button_font_style,
					fontWeight: pageStyles?.button?.primary_button_font_weight,
					fontSize: pageStyles?.button?.primary_button_font_size,
					lineHeight: pageStyles?.button?.primary_button_line_height,
					backgroundColor: pageStyles?.button?.primary_button_background_color,
					color: pageStyles?.button?.primary_button_color,
					border: pageStyles?.button?.primary_button_border,
					borderRadius: pageStyles?.button?.primary_button_border_radius,
					boxShadow: pageStyles?.button?.primary_button_box_shadow
				}
			},
			overrides: {
				MuiIconButton: {
					root: {
						color: pageStyles?.button?.primary_icon_button_color,
						border: pageStyles?.button?.primary_icon_button_border
					}
				},
				MuiDialog: {
					paper: {
						backgroundColor: "#FFFFFF"
					}
				},
				MuiAvatar: {
					colorDefault: {
						color: "#FFFFFF"
					}
				}
			}
		})

		return theme
	}
}

export default new PageTheme()
