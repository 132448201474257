import IMask from "imask"

const createPrefixMask = (
	validPrefixes: string[],
	currentValue: string,
	pattern: (prefix: string) => string
): IMask.MaskedPattern<string> => {
	// eslint-disable-next-line
	const asterisks = [...new Array(currentValue.length)].map(_ => "*").join("")

	const defaultPrefix = validPrefixes[0]

	const currentUsedPrefixIndex = validPrefixes.findIndex(prefix => currentValue.startsWith(prefix))

	let prefix: string

	if (currentUsedPrefixIndex === -1) {
		prefix = defaultPrefix
	} else {
		prefix = validPrefixes[currentUsedPrefixIndex]
	}

	const prefixMask = IMask.createMask({
		mask: `${pattern(prefix)}${asterisks}`
	})

	return prefixMask
}

export const formatPhoneNumber = (rawNumber: string) => {
	let formattedPhoneNumber: string = rawNumber

	if (rawNumber) {
		const phoneNumberMask = IMask.createMask({
			mask: "+55 (00) 00000-0000"
		})

		formattedPhoneNumber = phoneNumberMask.resolve(rawNumber)
	}

	return formattedPhoneNumber
}

export const formatUrl = (rawUrl: string) => {
	let formattedUrl: string = rawUrl

	if (rawUrl) {
		const validPrefixes = ["https", "http"]

		const urlMask = createPrefixMask(
			validPrefixes,
			rawUrl,
			(prefix) => `${prefix}://`
		)

		formattedUrl = urlMask.resolve(formattedUrl)

		formattedUrl = formattedUrl
			.replace(/\s/g, "")
	}

	return formattedUrl
}

export const formatEmail = (rawEmail: string) => {
	let formattedEmail: string = rawEmail

	if (rawEmail) {
		formattedEmail = rawEmail
			.toLowerCase()
			.replace(/\s/g, "")
	}

	return formattedEmail
}

export const formatPrice = (rawPrice: string) => {
	let formattedPrice: string = rawPrice

	if (rawPrice) {
		const priceNumberParams = rawPrice.match(/\d/g) as Array<number | string>

		if (!priceNumberParams) {
			const defaultStartParams = rawPrice.match(/R|\$/g)?.join("")

			return defaultStartParams || ""
		}

		const numberParamsLength = priceNumberParams.length

		const tempPriceNumberParams = [...priceNumberParams] as Array<number | string>

		tempPriceNumberParams
			.reverse()
			.forEach((_, index) => {
				const realIndex = numberParamsLength - index

				const indexAfterComma = index - 2

				if (index === 2) {
					priceNumberParams.splice(realIndex, 0, ",")
				} else if (indexAfterComma > 0 && indexAfterComma % 3 === 0) {
					priceNumberParams.splice(realIndex, 0, ".")
				}
			})

		formattedPrice = priceNumberParams.join("")

		formattedPrice = `R$${formattedPrice}`
	}

	return formattedPrice
}

export const formatFacebookPixelID = (rawFacebookPixelID: string) => {
	let formattedFacebookPixelID: string = rawFacebookPixelID

	if (rawFacebookPixelID) {
		const numberMask = IMask.createMask({
			mask: Number
		})

		formattedFacebookPixelID = numberMask.resolve(rawFacebookPixelID)
	}

	return formattedFacebookPixelID
}

export const formatGoogleAnalyticsID = (rawGoogleAnalyticsID: string) => {
	let formattedGoogleAnalyticsID: string = rawGoogleAnalyticsID

	if (rawGoogleAnalyticsID) {
		const validPrefixes = ["UA", "YT", "MO", "G"]

		const urlMask = createPrefixMask(
			validPrefixes,
			rawGoogleAnalyticsID,
			(prefix) => `${prefix}-`
		)

		formattedGoogleAnalyticsID = urlMask.resolve(formattedGoogleAnalyticsID)

		formattedGoogleAnalyticsID = formattedGoogleAnalyticsID
			.toUpperCase()
	}

	return formattedGoogleAnalyticsID
}

export const formatGooglePixelID = (rawGooglePixelID: string) => {
	let formattedGooglePixelID: string = rawGooglePixelID

	if (rawGooglePixelID) {
		const validPrefixes = ["AW"]

		const urlMask = createPrefixMask(
			validPrefixes,
			rawGooglePixelID,
			(prefix) => `${prefix}-`
		)

		formattedGooglePixelID = urlMask.resolve(formattedGooglePixelID)
	}

	return formattedGooglePixelID
}
