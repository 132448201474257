import React, { useState } from "react"
import {
	Dialog,
	Grid,
	Typography,
	ThemeProvider
} from "@material-ui/core"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"
import { isMobile } from "@/utils/checkDevice"
import { handleBackButton } from "@/utils/backButton"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/InfoDialog/styles"

type InfoDialogProps = {
	description: string
	closable?: boolean
}

type InfoDialogType = {
	open: (props: InfoDialogProps) => void
}

const InfoDialog: InfoDialogType & React.FC<InfoDialogProps> = (props) => {
	const {
		description,
		closable
	} = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		if (closable === false) {
			return
		}

		setOpened(false)
	}

	useDidMount(() => {
		if (isMobile) {
			handleBackButton(
				() => handleClose()
			)
		}
	})

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
			>
				<Grid
					container
					className={classes.dialogContainer}
				>
					<Typography
						variant="body1"
						color="textPrimary"
						align="center"
					>
						{description}
					</Typography>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

InfoDialog.open = (props: InfoDialogProps) => {
	renderComponent(
		"info-dialog",
		<InfoDialog
			{...props}
		/>
	)
}

export default InfoDialog
