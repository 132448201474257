import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	bannerContainer: {
		position: "relative",
		width: "100%",
		display: "block",
		height: 0,
		paddingBottom: "33.33%",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		borderRadius: theme.shape.borderRadius
	}
}))

export default useStyles
