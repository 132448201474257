import React from "react"
import { ThemeProvider } from "@material-ui/core"

import Routes from "@/routes"

import { AppContainer } from "@/components"

import appConfig from "@/config/app"

import useOrganization from "@/hooks/useOrganization"

const App = () => {
	const organization = useOrganization()

	return (
		<ThemeProvider
			theme={organization.theme}
		>
			<AppContainer
				id={appConfig.appContainerId}
			>
				<Routes />
			</AppContainer>
		</ThemeProvider>
	)
}

export default App
