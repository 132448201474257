import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	codeInput: {
		letterSpacing: theme.spacing(1)
	},
	form: {
		display: "flex",
		width: "100%",
		flexDirection: "column"
	}
}))

export default useStyles
