import { makeStyles } from "@material-ui/core"

const useCustomStyles = makeStyles(theme => ({
	addItemFab: {
		backgroundColor: theme.palette.background.default
	},
	addItemIcon: {
		color: "#ACB5BD"
	}
}))

export default useCustomStyles
