import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		position: "relative",
		height: 120
	},
	button: {
		position: "absolute",
		left: theme.spacing(2),
		bottom: theme.spacing(2),
		padding: 0,
		color: colors.palette.link
	},
	editSiteIcon: {
		position: "absolute",
		top: 16,
		right: 16
	}
}))

export default useStyles
