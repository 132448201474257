import React from "react"
import { useHistory } from "react-router-dom"
import {
	Typography,
	Grid
} from "@material-ui/core"

import useStyles from "@/@organizations/LastLink/pages/Setup/styles"

import { Divider, SetupListItem } from "@/components"

import Dialog from "@/@organizations/LastLink/pages/Setup/Dialog"

const Setup: React.FC = () => {
	const classes = useStyles()
	const history = useHistory()

	const handleGoToSiteList = () => {
		history.push("/site/list")
	}

	const handleOpenLastLinkDialog = () => {
		Dialog.open({})
	}

	return (
		<Grid
			className={classes.container}
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Qual o seu objetivo?
				</Typography>

				<Typography
					variant="body1"
				>
					Selecione a opção que representa seu perfil
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<SetupListItem
				onClick={handleGoToSiteList}
				title="Agregador de links"
				description="Crie em poucos passos uma página com suas informações e links e compartilhe de maneira fácil nas suas redes sociais"
			/>

			<Divider orientation="horizontal" size={2} />

			<SetupListItem
				onClick={handleOpenLastLinkDialog}
				title="Criador de conteúdo"
				description="Comece agora a monetizar seu Close Friends, grupos do Telegram, WhatsApp e muito mais. Foque no seu conteúdo sem se preocupar com o gerenciamento dos pagamentos. Vem conhecer"
			/>
		</Grid>
	)
}

export default Setup
