import React, { FormEvent, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
	Typography,
	TextField,
	Button,
	InputLabel,
	Grid,
	Link as MaterialLink,
	CircularProgress
} from "@material-ui/core"

import useStyles from "@/@organizations/LastLink/pages/SignInFirstStep/styles"
import useValidation from "@/hooks/useValidation"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { Divider } from "@/components"

import {
	formatEmail
} from "@/utils/mask"

type FormData = {
	email: string
}

export type LocationState = {
	email: string
}

const SignInFirstStep: React.FC = () => {
	const [formData, setFormData] = useState({} as FormData)
	const [loading, setLoading] = useState(false)

	const classes = useStyles()
	const validation = useValidation()
	const history = useHistory()

	const handleChangeFormData = (key: keyof FormData, value: string) => {
		validation.clearValidation(key)

		setFormData(lastState => ({
			...lastState,
			[key]: value
		}))
	}

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault()

		setLoading(true)

		try {
			await ApiService.post("/lastlink/signin/first-step", formData)

			history.push("/lastlink/signin/last-step", { email: formData.email } as LocationState)
		} catch (error) {
			validation.triggerValidation(error)
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	return (
		<Grid
			component="form"
			className={classes.form}
			onSubmit={handleSubmit}
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Login
				</Typography>

				<Typography
					variant="body1"
				>
					Não tem conta?
					{" "}
					<MaterialLink
						component={Link}
						to="/lastlink/signup"
					>
						Cadastre-se
					</MaterialLink>
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid>
				<InputLabel>E-mail</InputLabel>
				<TextField
					placeholder="nome@email.com"
					onChange={({ target }) => handleChangeFormData("email", target.value)}
					value={formatEmail(formData.email)}
					helperText={validation.messages.email}
					error={Boolean(validation.messages.email)}
					variant="outlined"
					fullWidth
				/>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Button
				variant="contained"
				color="primary"
				type="submit"
				disableElevation
				fullWidth
				disabled={loading}
				endIcon={loading && (
					<CircularProgress
						size={20}
					/>
				)}
			>
				Avançar
			</Button>
		</Grid>
	)
}

export default SignInFirstStep
