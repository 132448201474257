import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	form: {
		display: "flex",
		width: "100%",
		flexDirection: "column"
	}
})

export default useStyles
