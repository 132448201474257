import React from "react"
import { Grid } from "@material-ui/core"

import useStyles from "@/components/PageContainer/styles"

const PageContainer: React.FC = (props) => {
	const { children } = props

	const classes = useStyles()

	return (
		<Grid
			container
			className={classes.container}
		>
			<Grid
				className={classes.content}
			>
				{children}
			</Grid>
		</Grid>
	)
}

export default PageContainer
