import React from "react"
import { Grid } from "@material-ui/core"

import useStyles from "@/@fp/components/SectionDefaultLayout/styles"

import { useSectionStateStore } from "@/@fp/store/SectionState"

type DefaultProps = {
	style?: React.CSSProperties
	className?: string
	children?: React.ReactNode
}

export type SectionDefaultLayoutProps = {
	ContainerProps?: DefaultProps
	ContentProps?: DefaultProps
	sectionId?: number
}

const SectionDefaultLayout: React.FC<SectionDefaultLayoutProps> = (props) => {
	const { ContainerProps, ContentProps, children } = props

	const classes = useStyles()
	const sectionStateStore = useSectionStateStore()

	return (
		<Grid
			container
			style={{
				paddingTop: sectionStateStore?.sectionDefaultLayout?.firstSectionTopSpacing,
				...ContainerProps?.style
			}}
			className={ContainerProps?.className}
		>
			{ContainerProps?.children}
			<Grid
				container
				style={{
					paddingLeft: sectionStateStore?.sectionDefaultLayout?.sectionLateralSpacing,
					paddingRight: sectionStateStore?.sectionDefaultLayout?.sectionLateralSpacing,
					...ContentProps?.style
				}}
				className={`${classes.content} ${ContentProps?.className}`}
			>
				{ContentProps?.children || children}
			</Grid>
		</Grid>
	)
}

export default SectionDefaultLayout
