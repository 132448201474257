import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	variantAContainer: {
		display: "grid"
	},
	variantAContent: {
		paddingBottom: "100%",
		height: 0,
		width: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center"
	},
	variantDContainer: {
		display: "grid",
		gridTemplateColumns: "repeat(1, 1fr)"
	},
	variantDPicture: {
		height: "auto",
		width: "100%"
	}
})

export default useStyles
