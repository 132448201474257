import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	buttonContainer: {
		width: "100%",
		backgroundColor: colors.palette.background,
		backgroundSize: "cover",
		backgroundPosition: "center",
		height: theme.spacing(20),
		textTransform: "none",
		borderRadius: theme.spacing(0.5),
		border: "1px solid rgba(0, 0, 0, 0.23)"
	},
	input: {
		display: "none"
	}
}))

export default useStyles
