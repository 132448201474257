import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const PageSettingsPageDataSkeleton = () => (
	<Grid container>
		<Skeleton
			variant="rect"
			width="100%"
			height="80px"
			animation="wave"
		/>
	</Grid>
)

export default PageSettingsPageDataSkeleton
