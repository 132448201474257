import React, { useState } from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"
import { useParams } from "react-router-dom"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import PageService from "@/services/Page"

import {
	Divider,
	Loading
} from "@/components"

import PageTemplate from "@/@fp/templates/PageTemplate"
import { SiteSettings } from "@/@fp/protocols/Site"
import { Section } from "@/@fp/protocols/Section"
import { PageStyles, PageType } from "@/@fp/protocols/Page"

import useDidMount from "@/hooks/useDidMount"

import PageEditSkeleton from "@/skeletons/PageEdit"

import theme from "@/styles/theme"

import useStyles from "@/pages/PageList/PageEdit/styles"
import { useGlobalStateStore } from "@/store/GlobalState"

import QRCodeEditDrawer from "@/pages/PageList/PageEdit/QRCodeEditDrawer"
import PageSettingsEditDrawer from "@/pages/PageList/PageEdit/PageSettingsEditDrawer"
import PageDesignerEditDrawer from "@/pages/PageList/PageEdit/PageDesignerEditDrawer"

const PageEdit = () => {
	const classes = useStyles()
	const globalStateStore = useGlobalStateStore()

	const [loading, setLoading] = useState(true)

	const [pageType, setPageType] = useState<PageType>()
	const [siteSettings, setSiteSettings] = useState<SiteSettings>({} as SiteSettings)
	const [pageSections, setPageSections] = useState<Section[]>([])
	const [pageStyles, setPageStyles] = useState<PageStyles>({} as PageStyles)

	const { pageId, siteId } = useParams<{ pageId: string, siteId: string }>()

	const getPageData = async () => {
		const pageData = await PageService.getPageData(+pageId)

		if (pageData) {
			setPageType(pageData.type)
			setSiteSettings(pageData.siteSettings)
		}

		setLoading(false)
	}

	const getPageSections = async () => {
		try {
			const response = await ApiService.get("/site/page-sections", {
				params: {
					pageId
				}
			})

			setPageSections(response.data.sections)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const getPageStyles = async () => {
		try {
			const response = await ApiService.get("/site/page-styles", {
				params: {
					pageId
				}
			})

			setPageStyles(response.data.styles)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const getData = async () => {
		await Promise.all([
			getPageData(),
			getPageSections(),
			getPageStyles()
		])

		setLoading(false)
	}

	useDidMount(() => {
		getData()
	})

	return (
		<Grid
			container
			direction="column"
			wrap="nowrap"
		>
			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<Typography variant="h4">
					Editar página
				</Typography>

				<Grid
					container
					justify="flex-end"
					className={classes.iconButtonGroup}
				>
					{globalStateStore?.customer?.isTester && (
						<>
							<QRCodeEditDrawer
								siteId={+siteId}
								sitePageId={+pageId}
							/>

							<Divider orientation="vertical" size={1} />
						</>
					)}

					<PageDesignerEditDrawer
						sitePageId={+pageId}
						previewPageStyles={pageStyles}
						onPageDesignSave={getData}
					/>

					<Divider orientation="vertical" size={1} />

					<PageSettingsEditDrawer
						onTemplatePageSave={getData}
					/>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Loading loading={loading} customLoadingElement={<PageEditSkeleton />}>
				<>
					<Typography variant="body1" align="center">
						Toque no
						{" "}
						<b className={classes.fieldEditExampleText}>
							campo
						</b>
						{" "}
						desejado para editar.
					</Typography>

					<Divider orientation="horizontal" size={3} />

					<PageTemplate
						sections={pageSections}
						pageStyles={pageStyles}
						siteSettings={siteSettings}
						mode="edit"
						style={{
							boxShadow: theme.shadows[2]
						}}
						extradata={{
							pageType,
							pageId: +pageId,
							siteId: +siteId,
							customerId: globalStateStore.customer.id
						}}
					/>
				</>
			</Loading>
		</Grid>
	)
}

export default PageEdit
