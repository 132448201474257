import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	colorPreviewSkeleton: {
		border: `1px solid ${colors.greyScale[8]}`
	},
	colorSelectorContainer: {
		maxWidth: 225,
		"& .chrome-picker": {
			boxShadow: "none !important"
		}
	},
	buttonGroupContainer: {
		padding: theme.spacing(1)
	}
}))

export default useStyles
