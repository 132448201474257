import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	inputGroup: {
		paddingLeft: 0
	},
	input: {
		paddingLeft: 14
	},
	menu: {
		"& li": {
			display: "flex"
		}
	}
})

export default useStyles
