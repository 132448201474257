import React from "react"
import { Typography, TypographyTypeMap } from "@material-ui/core"

import useStyles from "@/@fp/components/Title/styles"

type TitleProps = TypographyTypeMap["props"] & {
	style?: React.CSSProperties
	className?: string
	component?: React.ElementType
}

const Title: React.FC<TitleProps> = (props) => {
	const classes = useStyles()

	const { children, className } = props

	return (
		<Typography
			{...props}
			variant="h1"
			className={`${classes.title} ${className}`}
		>
			{children}
		</Typography>
	)
}

export default Title
