import { getWindow } from "@/utils/node"

type UTMVariables = {
	utm_source: string
	utm_medium: string
	utm_campaign: string
}

class GoogleTagManager {
	static dataLayerVariables = {}

	static get UTMVariables (): UTMVariables {
		const urlParams = new URLSearchParams(window.location.search)

		return {
			utm_campaign: urlParams.get("utm_campaign") || "",
			utm_medium: urlParams.get("utm_medium") || "",
			utm_source: urlParams.get("utm_source") || ""
		}
	}

	static addDataLayerVariables (variables: object): void {
		const Window = getWindow()

		if (Window.dataLayer) {
			Window.dataLayer.push(variables)
		} else {
			Window.dataLayer = [variables]
		}

		this.dataLayerVariables = {
			...this.dataLayerVariables,
			...variables
		}

		/**
		 * Adds a DOM Loaded event on GTM, since that"s a SPA and
		 * gtm doesn't recognize by itself.
		 */
		this.triggerGoogleTagManagerDOMLoadedEvent()
	}

	private static triggerGoogleTagManagerDOMLoadedEvent (): void {
		const Window = getWindow()

		const DOMLoaded = {
			event: "gtm.dom",
			"gtm.uniqueEventId": 100
		}

		let lastGtmUniqueEventId = 0

		Object.keys(Window.dataLayer).forEach((key, index) => {
			if (key === "gtm.uniqueEventId") {
				lastGtmUniqueEventId = Window.dataLayer[index]["gtm.uniqueEventId"] + 1
			}
		})

		DOMLoaded["gtm.uniqueEventId"] = lastGtmUniqueEventId

		Window.dataLayer.push(DOMLoaded)
	}
}

export default GoogleTagManager
