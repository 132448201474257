import React from "react"
import {
	TextField,
	Select,
	MenuItem,
	TextFieldProps,
	SelectProps
} from "@material-ui/core"

import useStyles from "@/components/IconSelectInputGroup/styles"

type IconSelectInputGroupProps = {
	TextFieldProps: TextFieldProps
	SelectProps: SelectProps
	selectItemsData: Array<{
		value: string | number
		menuItemContent: React.ReactNode
		renderedContent?: React.ReactNode
	}>
}

const IconSelectInputGroup: React.FC<IconSelectInputGroupProps> = (props) => {
	const {
		TextFieldProps,
		SelectProps,
		selectItemsData
	} = props

	const classes = useStyles()

	return (
		<TextField
			{...TextFieldProps}
			InputProps={{
				startAdornment: (
					<Select
						MenuProps={{
							className: classes.menu
						}}
						{...SelectProps}
						renderValue={(value) => {
							const selectedItem = selectItemsData.find(item => item.value === value)

							if (!selectedItem) {
								return null
							}

							const { renderedContent, menuItemContent } = selectedItem

							if (renderedContent) {
								return renderedContent
							}

							return menuItemContent
						}}
					>
						{
							selectItemsData.map((selectItem) => (
								<MenuItem
									key={selectItem.value}
									value={selectItem.value}
								>
									{selectItem.menuItemContent}
								</MenuItem>
							))
						}
					</Select>
				),
				classes: {
					root: classes.inputGroup,
					input: classes.input
				}
			}}
		/>
	)
}

export default IconSelectInputGroup
