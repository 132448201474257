import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useCustomStyles = makeStyles({
	arrowIcon: {
		color: colors.greyScale[3]
	},
	hiddenElement: {
		opacity: 0,
		pointerEvents: "none"
	},
	toggleTitle: {
		color: colors.greyScale[1]
	},
	backgroundWhitedTextField: {
		backgroundColor: colors.greyScale[11]
	},
	selectInput: {
		padding: "12px 14px"
	},
	select: {
		backgroundColor: "white",
		"&::before": {
			display: "none"
		}
	},
	inlineText: {
		textOverflow: "ellipsis",
		whiteSpace: "pre-wrap",
		overflow: "hidden",
		maxWidth: "100%",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		flex: 1
	},
	listItemIcon: {
		color: "#2B292D"
	}
})

export default useCustomStyles
