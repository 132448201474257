import React, { FormEvent, useState } from "react"
import { useHistory } from "react-router-dom"
import {
	Typography,
	TextField,
	Button,
	Grid,
	Link as MaterialLink,
	CircularProgress
} from "@material-ui/core"

import useStyles from "@/@organizations/LastLink/pages/SignInLastStep/styles"
import useValidation from "@/hooks/useValidation"
import useDidMount from "@/hooks/useDidMount"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"
import AuthService from "@/services/Auth"

import { LocationState } from "@/@organizations/LastLink/pages/SignInFirstStep"

import { Divider } from "@/components"

type FormData = {
	authenticationCode: string
}

const SignInFirstStep: React.FC = () => {
	const [formData, setFormData] = useState({} as FormData)
	const [loading, setLoading] = useState(false)

	const classes = useStyles()
	const validation = useValidation()
	const history = useHistory<LocationState>()

	const email = history.location?.state?.email

	const handleChangeFormData = (key: keyof FormData, value: string) => {
		validation.clearValidation(key)

		setFormData(lastState => ({
			...lastState,
			[key]: value
		}))
	}

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault()

		setLoading(true)

		try {
			const result = await ApiService.post("/lastlink/signin/last-step", {
				...formData,
				email
			})

			const authToken = result.data.authToken

			AuthService.login(authToken)

			history.push("/lastlink/setup")
		} catch (error) {
			validation.triggerValidation(error)
			ErrorHandlerService.handle(error)
		}

		setLoading(false)
	}

	useDidMount(() => {
		if (!email) {
			history.push("/lastlink/signin/first-step")
		}
	})

	return (
		<Grid
			component="form"
			className={classes.form}
			onSubmit={handleSubmit}
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Acesse o seu e-mail
				</Typography>

				<Typography
					variant="body1"
				>
					Enviamos um código de 6 dígitos para o e-mail <b>{email}</b>, insira abaixo.
				</Typography>

				<Typography
					variant="overline"
				>
					Não recebeu o e-mail?
					{" "}
					<MaterialLink>
						Falar com o suporte
					</MaterialLink>
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Grid>
				<TextField
					type="number"
					placeholder="000000"
					onChange={({ target }) => handleChangeFormData("authenticationCode", target.value)}
					value={formData.authenticationCode}
					helperText={validation.messages.authenticationCode}
					error={Boolean(validation.messages.authenticationCode)}
					InputProps={{ className: classes.codeInput }}
					inputProps={{ maxLength: 6 }}
					variant="outlined"
					fullWidth
				/>
			</Grid>

			<Divider orientation="horizontal" size={3} />

			<Button
				variant="contained"
				color="primary"
				type="submit"
				disableElevation
				fullWidth
				disabled={loading}
				endIcon={loading && (
					<CircularProgress
						size={20}
					/>
				)}
			>
				Avançar
			</Button>
		</Grid>
	)
}

export default SignInFirstStep
