import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"

import GlobalStateProvider from "@/store/GlobalState"

import App from "@/App"

import "@/styles/global.css"
import "react-vis/dist/style.css"

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<GlobalStateProvider>
				<App />
			</GlobalStateProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
)
