import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	deletePageButton: {
		paddingLeft: 0,
		paddingRight: 0
	},
	templatePageContainer: {
		width: 70
	},
	formControlLabel: {
		alignItems: "flex-start"
	},
	formControlSwitch: {
		marginTop: "-6px"
	},
	toggleDescription: {
		maxWidth: 220,
		color: colors.greyScale[4]
	},
	formControlText: {
		lineHeight: "14px"
	}
})

export default useStyles
