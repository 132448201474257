import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0,
		border: "none",
		color: colors.greyScale[4],
		zIndex: 3
	},
	paper: {
		borderRadius: theme.spacing(1),
		overflowX: "hidden",
		padding: theme.spacing(6, 2, 2)
	}
}))

export default useStyles
