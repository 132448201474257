import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles({
	comingSoonTemplateContainer: {
		position: "absolute",
		zIndex: 9,
		width: "100%",
		height: "100%",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "rgba(255, 255, 255, 0.7)"
	},
	comingSoonChip: {
		backgroundColor: "#F784AD",
		color: colors.greyScale[11]
	},
	templatePageCategoryContainer: {
		textDecoration: "none"
	},
	categoryTitle: {
		flex: 1,
		fontSize: 14
	}
})

export default useStyles
