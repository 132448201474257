import { makeStyles } from "@material-ui/core"

import colors from "@/@fp/styles/colors"

const useStyles = makeStyles(theme => ({
	suggestionText: {
		cursor: "pointer",
		color: colors.palette.link,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		"&:hover": {
			opacity: 0.9,
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		}
	}
}))

export default useStyles
