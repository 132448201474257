import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"

import { Divider } from "@/components"

const useStyles = makeStyles({
	container: {
		height: "100%"
	},
	content: {
		flex: 1
	}
})

const MetricsEditDrawerSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Grid
				container
				direction="column"
				className={classes.container}
			>
				<Grid
					container
					alignItems="center"
					justify="center"
				>
					<Skeleton
						variant="rect"
						width={140}
						height={30}
						animation="wave"
					/>

					<Divider orientation="horizontal" size={1} />

					<Skeleton
						variant="rect"
						width={100}
						height={20}
						animation="wave"
					/>
				</Grid>

				<Divider orientation="horizontal" size={3} />

				<Skeleton
					variant="rect"
					width="100%"
					height={300}
					animation="wave"
				/>

				<Divider orientation="horizontal" size={3} />

				<Skeleton
					width={200}
					height={50}
				/>

				<Divider orientation="horizontal" size={1} />

				<Skeleton
					width="100%"
					height={40}
				/>

				<Skeleton
					width="100%"
					height={40}
				/>

				<Skeleton
					width="100%"
					height={40}
				/>
			</Grid>
		</>
	)
}

export default MetricsEditDrawerSkeleton
