import React from "react"
import { Skeleton } from "@material-ui/lab"

import { Divider, Portlet } from "@/components"

const PageEditSkeleton = () => (
	<>
		<Skeleton variant="rect" width="100%" height={25} animation="wave" />

		<Divider orientation="horizontal" size={3} />

		<Portlet>
			<Skeleton variant="rect" width="100%" height={200} animation="wave" />
		</Portlet>
	</>
)

export default PageEditSkeleton
