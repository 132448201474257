import React, { cloneElement } from "react"
import { IconButton as MaterialIconButton, IconButtonTypeMap, IconProps } from "@material-ui/core"

import useStyles from "@/@fp/components/IconButton/styles"

type IconButtonProps = IconButtonTypeMap["props"] & {
	href?: string
}

const IconButton: React.FC<IconButtonProps> = (props) => {
	const classes = useStyles()

	const { children } = props

	return (
		<MaterialIconButton
			{...props}
			className={classes.iconButton}
			{...({ target: "_blank", href: props.href || "#" })}
		>
			{cloneElement(children as React.ReactElement<IconProps>, {
				fontSize: "small"
			})}
		</MaterialIconButton>
	)
}

export default IconButton
