import React from "react"
import { Route } from "react-router-dom"

import Setup from "@/@organizations/LastLink/pages/Setup"

export const PrivateCPRoutes = [
	<Route
		exact
		key="lastlink-setup"
		path="/lastlink/setup"
		component={Setup}
	/>
]
