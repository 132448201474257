import { injectCSS } from "@/utils/dom"

import { DEFAULT_FONTS } from "@/components/FontSelect"

export const isBackgroundUrl = (backgroundProp: string) => backgroundProp?.startsWith("url(")

export const getUrlFromBackgroundProp = (backgroundProp: string) => {
	const isBackgroundUrlProp = isBackgroundUrl(backgroundProp)

	if (!isBackgroundUrlProp) {
		return null
	}

	const url = backgroundProp
		?.split("url(")
		?.pop()
		?.split(")")
		?.shift()

	return url
}

export const buildBackgroundPropFromUrl = (url: string) => {
	const backgroundProp = `url(${url}) center center / cover no-repeat`

	return backgroundProp
}

export const getColorFromBorderProp = (borderProp: string) => {
	if (!borderProp) {
		return null
	}

	const color = borderProp
		?.split(" ")
		?.pop()

	return color
}

export const loadFonts = (cssID: string, fontFamilies: string[]) => {
	const fontWasLoadedBefore = document.getElementById(cssID)

	if (fontWasLoadedBefore) {
		return
	}

	const uniqueFontFamilies = [...new Set(fontFamilies)]

	uniqueFontFamilies.forEach(fontFamily => {
		const encodedFontFamily = encodeURI(fontFamily)

		const fontUrl = `https://fonts.googleapis.com/css2?family=${encodedFontFamily}:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap`

		const css = `@import url('${fontUrl}');`

		injectCSS(css, cssID)
	})
}

export const loadAllFonts = () => {
	const cssID = "@font-select-component"

	const fontFamilies = DEFAULT_FONTS.map(defaultFont => defaultFont.fontFamily)

	loadFonts(cssID, fontFamilies)
}

export const isValidCSSColor = (color: string) => {
	if (!color) {
		return false
	}

	const isValidHexColor = color.startsWith("#")
	const isValidLinearCSSColor = color.includes("linear-gradient")

	const isValidCSSColor = isValidHexColor || isValidLinearCSSColor

	return isValidCSSColor
}
