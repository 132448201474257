import React, { createContext, useContext, useState } from "react"

import { SiteData } from "@/protocols/site"

type Site = SiteData["site"]

type Customer = SiteData["customer"]

type Organization = SiteData["organization"]

export interface GlobalStateContextData {
	site: Site
	customer: Customer
	organization: Organization
	setSiteData: (data: Partial<Site>) => void
	setCustomerData: (data: Partial<Customer>) => void
	setOrganizationData: (data: Partial<Organization>) => void
}

const GlobalStateStore = createContext<GlobalStateContextData>({} as GlobalStateContextData)

export const useGlobalStateStore = () => useContext(GlobalStateStore)

const GlobalStateProvider: React.FC = (props) => {
	const { children } = props

	const [site, setSite] = useState<Site>({} as Site)
	const [customer, setCustomer] = useState<Customer>({} as Customer)
	const [organization, setOrganization] = useState<Organization>({} as Organization)

	const setSiteData = (
		data: Partial<Site>
	) => {
		setSite(lastState => ({
			...lastState,
			...data
		}))
	}

	const setOrganizationData = (
		data: Partial<Organization>
	) => {
		setOrganization(lastState => ({
			...lastState,
			...data
		}))
	}

	const setCustomerData = (
		data: Partial<Customer>
	) => {
		setCustomer(lastState => ({
			...lastState,
			...data
		}))
	}

	return (
		<GlobalStateStore.Provider
			value={{
				site,
				customer,
				organization,
				setSiteData,
				setCustomerData,
				setOrganizationData
			}}
		>
			{children}
		</GlobalStateStore.Provider>
	)
}

export default GlobalStateProvider
