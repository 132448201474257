import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

type StyleProps = {
	tabBarCount: number
}

const useStyles = makeStyles(theme => ({
	tabBarContainer: {
		width: (props: StyleProps) => `${((90 - props.tabBarCount) / props.tabBarCount).toFixed(2)}%`,
		padding: theme.spacing(1, 0)
	},
	tabBarContent: {
		width: "100%",
		height: theme.spacing(0.5),
		borderRadius: theme.spacing(0.5),
		backgroundColor: colors.greyScale[2]
	},
	currentTabBar: {
		height: theme.spacing(0.5),
		width: "100%",
		borderRadius: theme.spacing(0.5),
		backgroundColor: colors.greyScale[1]
	},
	tabContainer: {
		position: "absolute",
		top: theme.spacing(1),
		zIndex: 9,
		cursor: "pointer"
	}
}))

export default useStyles
