import React from "react"
import {
	Typography,
	Grid
} from "@material-ui/core"

import useStyles from "@/components/SectionPlaceholderText/styles"

import { useSectionStateStore } from "@/@fp/store/SectionState"

type SectionPlaceholderTextProps = {
	text: string
}

const SectionPlaceholderText: React.FC<SectionPlaceholderTextProps> = (props) => {
	const sectionStateStore = useSectionStateStore()
	const classes = useStyles({
		lateralSpacing: sectionStateStore?.sectionDefaultLayout?.sectionLateralSpacing,
		topSpacing: sectionStateStore?.sectionDefaultLayout?.firstSectionTopSpacing || 0
	})

	const { text } = props

	return (
		<Grid
			justify="center"
			container
			className={classes.container}
		>
			<Typography
				className={classes.text}
				align="center"
			>
				{text}
			</Typography>
		</Grid>
	)
}

export default SectionPlaceholderText
