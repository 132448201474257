import React from "react"
import { Button as MaterialButton, ButtonProps } from "@material-ui/core"

import useStyles from "@/@fp/components/Button/styles"

const Button: React.FC<ButtonProps> = (props) => {
	const classes = useStyles()

	const { children, className, href, ...otherProps } = props

	return (
		<MaterialButton
			{...otherProps}
			variant="outlined"
			color="inherit"
			fullWidth
			className={`${classes.button} ${className}`}
			{...(otherProps.type !== "submit" ? {
				href: href || "#",
				target: "_blank"
			} : {})}
		>
			{children}
		</MaterialButton>
	)
}

export default Button
