import React from "react"
import { Grid } from "@material-ui/core"
import Gallery, { RenderImageProps } from "react-photo-gallery"

import useStyles from "@/@fp/components/PictureGallery/styles"

/**
 * a - squared pictures
 * b - vertical mosaic
 * c - horizontal mosaic
 * d - single column list
 */
export type PictureGalleryVariant = "a" | "b" | "c" | "d"

type Picture = {
	alt: string
	src: string
	width: number
	height: number
}

type PictureGalleryProps = {
	variant: PictureGalleryVariant
	margin: number
	/**
	 * Only for (variant: c)
	 */
	targetRowHeight?: number
	/**
	 * Only for (variant: a) and (variant: b)
	 */
	columns?: number
	pictures: Picture[]
	onClick?: (index: number, picture: Picture) => void
}

const PictureGallery: React.FC<PictureGalleryProps> = (props) => {
	const {
		variant,
		pictures,
		margin,
		targetRowHeight,
		columns,
		onClick
	} = props

	const classes = useStyles()

	const defaultStyle = {
		gridGap: `${margin * 2}px`,
		cursor: onClick ? "pointer" : "normal"
	}

	const handleClick = (index: number, picture: Picture) => {
		if (onClick) {
			onClick(index, picture)
		}
	}

	/**
	 * This method was added as a wrapper since horizontal mosaic
	 * uses the src as key by default and it causes some bugs when
	 * we are reordering images with duplicated src inside the mosaic.
	 */
	const handleHorizontalMosaicImageRendering = (props: RenderImageProps) => (
		<img
			style={{
				top: props.top,
				left: props.left,
				margin: props.margin,
				cursor: defaultStyle.cursor
			}}
			src={props.photo.src}
			/**
			 * We add a custom key to avoid duplication, by default mosaic uses src as key.
			 */
			key={props.index}
			alt={props.photo.alt}
			width={props.photo.width}
			height={props.photo.height}
			onClick={(event) => {
				if (props.onClick) {
					props.onClick(event, {
						photo: props.photo,
						index: props.index
					// eslint-disable-next-line
					} as any)
				}
			}}
		/>
	)

	return (
		<>
			{variant === "a" && (
				<Grid
					container
					direction="column"
					wrap="nowrap"
					className={classes.variantAContainer}
					style={{
						...defaultStyle,
						gridTemplateColumns: `repeat(${columns}, 1fr)`
					}}
				>
					{pictures.map((picture, index) => (
						<Grid
							key={index}
							container
							className={classes.variantAContent}
							onClick={() => handleClick(index, picture)}
							style={{
								backgroundImage: `url(${picture.src})`
							}}
						/>
					))}
				</Grid>
			)}

			{variant === "b" && (
				<Gallery
					photos={pictures}
					margin={margin}
					direction="column"
					columns={columns}
					onClick={(_, { photo, index }) => handleClick(index, photo as Picture)}
				/>
			)}

			{variant === "c" && (
				<Gallery
					photos={pictures}
					direction="row"
					margin={margin}
					targetRowHeight={targetRowHeight}
					onClick={(_, { photo, index }) => handleClick(index, photo as Picture)}
					renderImage={handleHorizontalMosaicImageRendering}
				/>
			)}

			{variant === "d" && (
				<Grid
					container
					direction="column"
					wrap="nowrap"
					className={classes.variantDContainer}
					style={{
						...defaultStyle
					}}
				>
					{pictures.map((picture, index) => (
						<img
							key={index}
							src={picture.src}
							alt={picture.alt}
							className={classes.variantDPicture}
							onClick={() => handleClick(index, picture)}
						/>
					))}
				</Grid>
			)}
		</>
	)
}

export default PictureGallery
