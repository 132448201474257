import copy from "copy-to-clipboard"

import { useGlobalStateStore } from "@/store/GlobalState"

import { Notification } from "@/components"

type UseShareResponse = {
	copySiteFPUrl: () => void
	shareSiteFPUrl: () => Promise<void>
	copy: (text: string) => void
}

const useShare = (): UseShareResponse => {
	const globalStateStore = useGlobalStateStore()

	const siteFPUrl = globalStateStore?.site?.url

	const copyText = (text: string) => {
		copy(text)

		Notification.success({
			message: "Link copiado com sucesso!"
		})
	}

	const shareUrl = async (url: string) => {
		if (!navigator.share) {
			return false
		}

		await navigator.share({ url })

		return true
	}

	const copySiteFPUrl = () => {
		copyText(siteFPUrl)
	}

	const shareSiteFPUrl = async () => {
		const shareResult = await shareUrl(siteFPUrl)

		/**
		 * Usually sharing with navigator only works on mobile devices,
		 * so we make a fallback to clipboard copying in case the sharing
		 * is not enabled.
		 */
		if (!shareResult) {
			copySiteFPUrl()
		}
	}

	return {
		copySiteFPUrl,
		shareSiteFPUrl,
		copy: copyText
	}
}

export default useShare
