import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	organizationBrand: {
		maxWidth: "100%",
		maxHeight: theme.spacing(3)
	}
}))

export default useStyles
