import React, { useState } from "react"
import { useHistory } from "react-router"
import {
	Grid,
	Typography,
	Button,
	CircularProgress
} from "@material-ui/core"

import {
	OrganizationAdvertisingBanner,
	Divider,
	Loading,
	PlanBlockPopup
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import useDidMount from "@/hooks/useDidMount"
import useOrganization from "@/hooks/useOrganization"

import SiteListItem from "@/pages/SiteList/SiteListItem"

import SiteListSkeleton from "@/skeletons/SiteList"

import { getStatusCode } from "@/utils/response"

type Site = {
	id: number
	url: string
	slug: string
	title: string
}

const SiteList: React.FC = () => {
	const [loadingSiteCreation, setLoadingSiteCreation] = useState(false)
	const [loading, setLoading] = useState(true)

	const [sites, setSites] = useState<Site[]>([])

	const organization = useOrganization()
	const history = useHistory()

	const loadSites = async () => {
		try {
			const { data } = await ApiService.get("/site/list")

			setSites(data.sites)
		} catch (error) {
			ErrorHandlerService.handle(error)
		}
	}

	const handleOpenSite = (siteId: number) => {
		history.push(`/sites/${siteId}/pages`)
	}

	const handleCreateSite = async () => {
		setLoadingSiteCreation(true)

		try {
			const { data } = await ApiService.post("/site")

			handleOpenSite(data.id)
		} catch (error) {
			ErrorHandlerService.handle(error)

			const statusCode = getStatusCode(error)

			if (statusCode === 402) {
				PlanBlockPopup.open({
					featureAction: "criar mais sites",
					planButtonLink: organization.mountPlanPageUrl("plan")
				})
			}
		}

		setLoadingSiteCreation(false)
	}

	const load = async () => {
		await loadSites()

		setLoading(false)
	}

	useDidMount(() => {
		load()
	})

	return (
		<Grid
			container
			direction="column"
		>
			<Grid>
				<Typography
					variant="h3"
				>
					Meus Sites
				</Typography>
			</Grid>

			<Divider orientation="horizontal" size={2} />

			<Button
				onClick={handleCreateSite}
				variant="contained"
				color="primary"
				disableElevation
				fullWidth
				disabled={loadingSiteCreation}
				endIcon={loadingSiteCreation && (
					<CircularProgress
						size={20}
					/>
				)}
			>
				Novo Site
			</Button>

			<Divider orientation="horizontal" size={3} />

			{organization.data.bannerImageUrl && (
				<>
					<OrganizationAdvertisingBanner
						bannerImageUrl={organization.data.bannerImageUrl}
						bannerRedirectUrl={organization.data.bannerRedirectUrl || "#"}
						style={{
							paddingBottom: organization.data.bannerPaddingBottom
						}}
					/>

					<Divider orientation="horizontal" size={3} />
				</>
			)}

			<Loading
				loading={loading}
				customLoadingElement={<SiteListSkeleton />}
			>
				<Grid
					container
					direction="column"
					spacing={2}
				>
					{sites.map(site => (
						<Grid
							item
							xs={12}
							key={site.id}
						>
							<SiteListItem
								onClick={() => handleOpenSite(site.id)}
								name={site.title || site.slug}
								slug={site.slug}
								url={site.url}
							/>
						</Grid>
					))}
				</Grid>
			</Loading>
		</Grid>
	)
}

export default SiteList
