import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { OrganizationType, Organization } from "@/protocols/organization"

class OrganizationService {
	async getByType (type: OrganizationType): Promise<Organization | null> {
		try {
			const response = await ApiService.get("/organization", {
				params: {
					type
				}
			})

			const organization = response.data

			return organization
		} catch (error) {
			ErrorHandlerService.handle(error)
			return null
		}
	}
}

export default new OrganizationService()
