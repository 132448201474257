import React from "react"
import { ThemeProvider, Grid } from "@material-ui/core"

import { Page, PageExtradata } from "@/@fp/protocols/Page"
import { SiteSettings, SiteIntegration, SiteExtradata } from "@/@fp/protocols/Site"
import { SectionMode } from "@/@fp/protocols/Section"

import SectionTemplate from "@/@fp/templates/SectionTemplate"

import { Divider, Portlet } from "@/components"
import { OrganizationBrand } from "@/@fp/components"

import { isLastItem, isFirstItem } from "@/utils/array"

import PageThemeService from "@/@fp/services/PageTheme"
import IntegrationService from "@/@fp/services/Integration"

import useDidMount from "@/hooks/useDidMount"
import useOrganization from "@/hooks/useOrganization"

import SectionStateProvider from "@/@fp/store/SectionState"

import { getColorsFromCSSBackground, turnColorIntoHex, isLightOrDark } from "@/utils/color"

type PageTemplateProps = {
	mode: SectionMode
	sections?: Page["sections"]
	style?: React.CSSProperties
	siteSettings?: SiteSettings
	siteIntegrations?: SiteIntegration[]
	siteExtraData?: SiteExtradata
	pageStyles?: Page["styles"]
	extradata: PageExtradata
}

const FP_DIVIDER_SIZE_RATIO = 3

const PageTemplate: React.FC<PageTemplateProps> = (props) => {
	const {
		sections,
		mode,
		style,
		pageStyles,
		siteSettings,
		siteIntegrations,
		extradata
	} = props

	const buildedPageTheme = PageThemeService.retrieveBuildedTheme(pageStyles)

	const organization = useOrganization()

	const Sections = () => (
		<>
			{sections?.map((section, sectionIndex) => (
				<SectionStateProvider
					sectionDefaultLayout={{
						...(isFirstItem(sectionIndex) ? {
							firstSectionTopSpacing: mode === "edit" ? (
								buildedPageTheme.spacing(2)
							) : (
								buildedPageTheme.spacing(extradata?.isUniquePage ? 2 : 6)
							)
						} : {})
					}}
				>
					<SectionTemplate
						key={section.id}
						id={section.id}
						properties={section.properties}
						content={section.content}
						file={section.file}
						mode={mode}
						pageType={extradata?.pageType}
						pageId={extradata?.pageId}
						siteId={extradata?.siteId}
						customerId={extradata?.customerId}
					/>

					{!isLastItem(sectionIndex, sections.length) && (
						<>
							{mode === "edit" ? (
								<Divider orientation="horizontal" size={1} />
							) : (
								(Object.values(section.content).some(value => value)) && (
									<Divider orientation="horizontal" size={FP_DIVIDER_SIZE_RATIO} />
								)
							)}
						</>
					)}
				</SectionStateProvider>
			))}
		</>
	)

	const enablePageIntegrations = () => {
		siteIntegrations?.forEach(integration => {
			IntegrationService.enableIntegration(integration.type, integration.integrationId)
		})
	}

	const getContrastedOrganizationLogoColor = (cssBackground: string): string => {
		const light = "#FFFFFF"
		const dark = "#000000"

		const backgroundColors = getColorsFromCSSBackground(cssBackground)

		const lastBackgroundColor = backgroundColors.pop()

		if (!lastBackgroundColor) {
			const pageTextPrimaryColor = buildedPageTheme.palette.text.primary

			if (!pageTextPrimaryColor) {
				return dark
			}

			const pageTextPrimaryColorType = isLightOrDark(pageTextPrimaryColor)

			if (pageTextPrimaryColorType === "dark") {
				return dark
			} else {
				return light
			}
		}

		const backgroundHexColor = turnColorIntoHex(lastBackgroundColor)

		const backgroundColorType = isLightOrDark(backgroundHexColor)

		if (backgroundColorType === "dark") {
			return light
		} else {
			return dark
		}
	}

	useDidMount(() => {
		enablePageIntegrations()
	})

	const background = mode === "edit" ? (
		buildedPageTheme.palette.background.paper
	) : (
		buildedPageTheme.palette.background.default
	)

	const minHeight = mode === "edit" ? "auto" : "100%"

	return (
		<ThemeProvider
			theme={buildedPageTheme}
		>
			<Portlet
				style={{
					...(style || {}),
					display: "flex",
					height: "auto",
					flex: "1 0 auto",
					userSelect: "none",
					WebkitUserSelect: "none",
					MozUserSelect: "none",
					msUserSelect: "none",
					paddingLeft: 0,
					paddingRight: 0,
					paddingTop: 0,
					background,
					minHeight
				}}
			>
				<Grid
					container
					direction="column"
					style={{
						flex: "1 0 auto"
					}}
				>
					<Sections />

					{siteSettings?.organizationBrand && (
						<OrganizationBrand
							url={organization.data.landingPageUrl}
							src={organization.data.fpLogo}
							hexColor={getContrastedOrganizationLogoColor(background)}
							containerStyle={{
								marginTop: "auto",
								paddingTop: buildedPageTheme.spacing(FP_DIVIDER_SIZE_RATIO)
							}}
						/>
					)}
				</Grid>
			</Portlet>
		</ThemeProvider>
	)
}

export default PageTemplate
