import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	card: {
		width: "100%",
		height: 260,
		backgroundColor: "#FFFFFF",
		borderRadius: 4
	},
	pageTitle: {
		marginBottom: theme.spacing(2)
	}
}))

const TemplatePageListSkeleton = () => {
	const classes = useStyles()

	const templatePages = []

	const totalItems = 1

	// eslint-disable-next-line
	for (let i = 0; i < totalItems; i++) {
		templatePages.push(
			<Grid
				key={i}
				item
				xs={6}
				sm={6}
				md={6}
				lg={6}
				xl={6}
			>
				<Card
					className={classes.card}
					elevation={2}
				>
					<Skeleton variant="rect" width="100%" height="100%" animation="wave" />
				</Card>
			</Grid>
		)
	}

	return (
		<Grid container spacing={2}>
			<Grid
				item
				xs={12}
				sm={12}
				md={12}
				lg={12}
				xl={12}
				className={classes.pageTitle}
			>
				<Skeleton variant="rect" width="50%" height="16px" animation="wave" />
			</Grid>

			{templatePages}
		</Grid>
	)
}

export default TemplatePageListSkeleton
