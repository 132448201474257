import { makeStyles } from "@material-ui/core"

import { isMobile } from "@/utils/checkDevice"

const useStyles = makeStyles(theme => ({
	tinySliderContainer: {
		position: "relative",
		height: "100%",
		"& #tns1-mw": {
			height: "100%"
		},
		"& .tns-inner": {
			height: "100%"
		},
		"& .tns-slider": {
			height: "100%"
		},
		"& .tns-outer": {
			width: "100%"
		}
	},
	tinySliderContent: {
		height: "100%",
		overflowY: "auto",
		[theme.breakpoints.up("sm")]: {
			"&::-webkit-scrollbar": {
				width: "10px",
				height: "10px"
			},
			"&::-webkit-scrollbar-thumb": {
				backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0.33)), linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5))",
				backgroundBlendMode: "multiply, screen",
				backgroundColor: "rgba(204, 204, 204, 0.5)",
				borderRadius: "5px"
			},
			"&::-webkit-scrollbar-track": {
				backgroundColor: "transparent"
			}
		}
	},
	slideHalfScreenAnimation: {
		"& .tns-slider .tns-item": {
			animation: "1s ease 1s 1 normal none running $slideHalfScreen"
		}
	},
	"@keyframes slideHalfScreen": {
		"0%": {
			transform: "translate3d(0px, 0px, 0px)"
		},
		"50%": {
			transform: `translate3d(${isMobile ? "-50vw" : "-210px"}, 0px, 0px)`
		},
		"100%": {
			transform: "translate3d(0px, 0px, 0px)"
		}
	}
}))

export default useStyles
