import React, { createContext, useContext } from "react"

import defaultTheme from "@/styles/theme"

export type SectionDefaultLayout = {
	firstSectionTopSpacing?: number
	sectionLateralSpacing?: number
	drawerDashInternalSpacing?: number
	drawerContentSpacing?: number
}

export interface SectionStateContextData {
	sectionDefaultLayout?: SectionDefaultLayout
}

const SectionStateStore = createContext<SectionStateContextData>({} as SectionStateContextData)

export const useSectionStateStore = () => useContext(SectionStateStore)

type SectionStateProviderProps = {
	sectionDefaultLayout?: SectionDefaultLayout
}

const SectionStateProvider: React.FC<SectionStateProviderProps> = (props) => {
	const { children, sectionDefaultLayout } = props

	const defaultSectionDefaultLayoutData = {
		sectionLateralSpacing: defaultTheme.spacing(2),
		drawerDashInternalSpacing: defaultTheme.spacing(1),
		/**
		 * Must be the sum of the two above, is usually
		 * the space needed to display content exactly inside
		 * drawer dash.
		 */
		drawerContentSpacing: defaultTheme.spacing(3)
	} as SectionDefaultLayout

	return (
		<SectionStateStore.Provider
			value={{
				sectionDefaultLayout: {
					...sectionDefaultLayout,
					...defaultSectionDefaultLayoutData
				}
			}}
		>
			{children}
		</SectionStateStore.Provider>
	)
}

export default SectionStateProvider
