import React, { useState, useRef } from "react"
import {
	ChromePicker as ColorSelector,
	ColorChangeHandler
} from "react-color"
import {
	Popover,
	Grid,
	Button,
	ButtonBase
} from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"

import { Divider } from "@/components"

import useStyles from "@/components/ColorPicker/styles"

type ColorPickerProps = {
	onChange: (color: string) => void
	value: string
}

const ColorPicker: React.FC<ColorPickerProps> = (props) => {
	const { onChange, value } = props

	const classes = useStyles()

	const [opened, setOpened] = useState(false)
	const [currentColor, setCurrentColor] = useState(value)
	const colorPreviewRef = useRef()

	const handleClosePopover = () => {
		setOpened(false)
	}

	const handleOpenPopover = () => {
		setOpened(true)
	}

	const handleSave = () => {
		handleClosePopover()

		onChange(currentColor)
	}

	const handleChange: ColorChangeHandler = (color) => {
		setCurrentColor(color.hex)
	}

	return (
		<>
			<Popover
				open={opened}
				anchorEl={colorPreviewRef.current}
				onClose={handleClosePopover}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left"
				}}
			>
				<Grid
					container
					alignItems="center"
					justify="center"
					className={classes.colorSelectorContainer}
				>
					<ColorSelector
						color={currentColor}
						onChange={handleChange}
					/>

					<Divider size={1} orientation="horizontal" />

					<Grid
						justify="space-between"
						container
						className={classes.buttonGroupContainer}
					>
						<Grid item>
							<Button
								onClick={handleClosePopover}
								color="inherit"
							>
								Cancelar
							</Button>
						</Grid>

						<Grid item>
							<Button
								onClick={handleSave}
								variant="contained"
								color="primary"
								disabled={currentColor === value}
								disableElevation
							>
								Salvar
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Popover>
			<Grid
				item
				onClick={handleOpenPopover}
			>
				<Skeleton
					width={42}
					height={42}
					variant="circle"
					animation={false}
					component={ButtonBase}
					innerRef={colorPreviewRef}
					className={classes.colorPreviewSkeleton}
					style={{
						background: value
					}}
				/>
			</Grid>
		</>
	)
}

export default ColorPicker
