import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	button: {
		textTransform: "none",
		padding: theme.spacing(2),
		backgroundColor: theme.typography.button.backgroundColor,
		color: theme.typography.button.color,
		border: theme.typography.button.border,
		borderRadius: theme.typography.button.borderRadius,
		boxShadow: theme.shadows[1],
		textAlign: "center"
	}
}))

export default useStyles
