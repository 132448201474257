import { createMuiTheme } from "@material-ui/core"

import colors from "@/@fp/styles/colors"

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
	palette: {
		background: {
			default: colors.palette.background,
			paper: colors.greyScale[11]
		},
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: "#FFF"
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		},
		text: {
			primary: colors.greyScale[1],
			secondary: colors.greyScale[3]
		}
	},
	typography: {
		fontFamily: "Work Sans",
		h1: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "22px",
			lineHeight: "32px"
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "24px"
		},
		body1: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "14px",
			lineHeight: "24px"
		},
		button: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "18px",
			lineHeight: "24px",
			backgroundColor: colors.greyScale[11],
			color: colors.greyScale[1],
			border: `1px solid ${colors.greyScale[1]}`
		}
	},
	overrides: {
		MuiIconButton: {
			root: {
				color: colors.greyScale[4],
				border: `1px solid ${colors.greyScale[4]}`
			}
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		0: "0px 0px 0px rgba(0, 0, 0, 0)",
		1: "0px 2px 4px rgba(33, 36, 41, 0.08)",
		2: "0px 4px 16px rgba(33, 36, 41, 0.24)"
	// eslint-disable-next-line
	}) as any
})

export default theme
