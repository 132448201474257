import React from "react"

import {
	Grid,
	Button,
	CircularProgress,
	Typography
} from "@material-ui/core"

import useFileUpload, { UploadFileResponse } from "@/hooks/useFileUpload"

import useStyles from "@/@fp/components/EditDrawerSectionPictureUpload/styles"

import { Divider } from "@/components"

import { ReactComponent as UploadIcon } from "@/assets/icons/upload.svg"

type EditDrawerSectionPictureUploadProps = {
	onPictureUploaded: (data: UploadFileResponse<unknown>) => Promise<void>
	requestPath: string
	pictureUrl?: string
	classes?: {
		buttonContainer?: string
		container?: string
	}
}

const EditDrawerSectionPictureUpload: React.FC<EditDrawerSectionPictureUploadProps> = (props) => {
	const {
		onPictureUploaded,
		requestPath,
		pictureUrl,
		classes: customClasses
	} = props

	const fileUpload = useFileUpload({
		requestPath
	})
	const classes = useStyles()

	const handleUploadPicture = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputElement = event?.target

		const picture = inputElement?.files?.[0]

		inputElement.value = ""

		if (picture) {
			const uploadedPictureData = await fileUpload.uploadFile(
				picture,
				"asset"
			)

			if (!uploadedPictureData.error) {
				onPictureUploaded(uploadedPictureData)
			}
		}
	}

	return (
		<Grid
			container
			alignItems="center"
			direction="column"
			className={customClasses?.container}
		>
			<Button
				component="label"
				className={`${classes.buttonContainer} ${customClasses?.buttonContainer}`}
				style={{
					backgroundImage: (
						pictureUrl && (
							`linear-gradient(to top, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${pictureUrl})`
						)
					)
				}}
			>
				{fileUpload.isUploading ? (
					<CircularProgress />
				) : (
					<Grid
						container
						justify="center"
					>
						<UploadIcon
							style={{ color: pictureUrl && "#FFFFFF" }}
						/>

						<Divider size={1} orientation="horizontal" />

						<Typography
							variant="body1"
							color="textPrimary"
							style={{ color: pictureUrl && "#FFFFFF" }}
						>
							Fazer upload de imagem
						</Typography>
					</Grid>
				)}

				<input
					type="file"
					className={classes.input}
					onChange={handleUploadPicture}
					accept="image/*"
				/>
			</Button>
		</Grid>
	)
}

export default EditDrawerSectionPictureUpload
