import envConfig from "@/config/env"

export const isFPClient = () => {
	if (envConfig.isDevEnvironment) {
		const isFPClient = window.location.host.includes("minisite.localhost")

		return isFPClient
	}

	const FPHosts = ["lastlink.bio"]

	const isFPClient = FPHosts.includes(window.location.host)

	return isFPClient
}
