import React from "react"
import {
	WhatsApp as WhatsAppIcon,
	Link as LinkIcon,
	YouTube as YoutubeIcon,
	AddShoppingCart as ProductIcon,
	Apps as PortfolioIcon,
	List as MenuIcon,
	PermIdentity as LeadIcon
} from "@material-ui/icons"

import { PageType } from "@/@fp/protocols/Page"

import whatsappPagePreviewImg from "@/assets/whatsapp_page_preview.png"
import linkPagePreviewImg from "@/assets/link_page_preview.png"
import youtubePagePreviewImg from "@/assets/youtube_page_preview.png"

type TemplatePageCategoryMap = {
	[key in PageType]: {
		previewImg?: string
		icon: JSX.Element
		style?: React.CSSProperties
	}
}

export const templatePageCategoryMap: TemplatePageCategoryMap = {
	whatsapp: {
		previewImg: whatsappPagePreviewImg,
		style: { backgroundSize: "cover" },
		icon: <WhatsAppIcon style={{ color: "#25D366" }} />
	},
	link: {
		previewImg: linkPagePreviewImg,
		style: { backgroundSize: "cover" },
		icon: <LinkIcon style={{ color: "#7048E8" }} />
	},
	youtube: {
		previewImg: youtubePagePreviewImg,
		style: { backgroundSize: "contain" },
		icon: <YoutubeIcon style={{ color: "#FF0000" }} />
	},
	product: {
		icon: <ProductIcon style={{ color: "#FF7143" }} />
	},
	portfolio: {
		icon: <PortfolioIcon style={{ color: "#6F16FF" }} />
	},
	menu: {
		icon: <MenuIcon style={{ color: "#FFA0A0" }} />
	},
	lead: {
		icon: <LeadIcon style={{ color: "#8F00FF" }} />
	}
}
