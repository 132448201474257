import React, { lazy, Suspense } from "react"
import { Grid } from "@material-ui/core"
import Skeleton from "@material-ui/lab/Skeleton"

import { SectionProps, SectionFile } from "@/@fp/protocols/Section"

import { useSectionStateStore } from "@/@fp/store/SectionState"

const Bio1 = lazy(() => import("@/@fp/sections/Bio1"))
const Description1 = lazy(() => import("@/@fp/sections/Description1"))
const Link1 = lazy(() => import("@/@fp/sections/Link1"))
const Product1 = lazy(() => import("@/@fp/sections/Product1"))
const Profile1 = lazy(() => import("@/@fp/sections/Profile1"))
const SocialMedia1 = lazy(() => import("@/@fp/sections/SocialMedia1"))
const WhatsApp1 = lazy(() => import("@/@fp/sections/WhatsApp1"))
const WhatsAppContact1 = lazy(() => import("@/@fp/sections/WhatsAppContact1"))
const Youtube1 = lazy(() => import("@/@fp/sections/Youtube1"))
const Portfolio1 = lazy(() => import("@/@fp/sections/Portfolio1"))
const Banner1 = lazy(() => import("@/@fp/sections/Banner1"))
const OldProduct1 = lazy(() => import("@/@fp/sections/OldProduct1"))
const Lead1 = lazy(() => import("@/@fp/sections/Lead1"))
const YoutubeVideo1 = lazy(() => import("@/@fp/sections/YoutubeVideo1"))

type SectionsMap = {
	// eslint-disable-next-line
	[key in SectionFile]: React.FC<any>
}

const SectionTemplate: React.FC<SectionProps> = (props) => {
	const { pageType } = props

	const sectionStateStore = useSectionStateStore()

	const sectionsMap: SectionsMap = {
		Bio1,
		Description1,
		Link1,
		Product1: pageType === "menu" ? Product1 : OldProduct1,
		Profile1,
		SocialMedia1,
		WhatsApp1,
		WhatsAppContact1,
		Youtube1,
		Portfolio1,
		Banner1,
		Lead1,
		YoutubeVideo1
	}

	const LazyLoadedSectionComponent = sectionsMap[props.file]

	if (!LazyLoadedSectionComponent) {
		return null
	}

	return (
		<Suspense
			fallback={(
				<Grid
					style={{
						padding: `0px ${sectionStateStore?.sectionDefaultLayout?.sectionLateralSpacing}px`,
						paddingTop: sectionStateStore?.sectionDefaultLayout?.firstSectionTopSpacing || 0
					}}
				>
					<Skeleton
						variant="rect"
						width="100%"
						height={70}
						animation="wave"
					/>
				</Grid>
			)}
		>
			<LazyLoadedSectionComponent {...props} />
		</Suspense>
	)
}

export default SectionTemplate
