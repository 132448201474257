import React, { useState, useEffect } from "react"
import {
	Grid,
	Fab,
	Tabs,
	Tab,
	Typography
} from "@material-ui/core"
import {
	PaletteOutlined as DesignerIcon
} from "@material-ui/icons"

import {
	EditDrawer,
	Divider,
	ImageUpload,
	ColorPicker,
	FontSelect,
	ButtonSelect
} from "@/components"

import ApiService from "@/services/Api"
import ErrorHandlerService from "@/services/ErrorHandler"

import { PageStyles } from "@/@fp/protocols/Page"

import { Button as ButtonType } from "@/components/ButtonSelect"

import { UploadFileResponse } from "@/hooks/useFileUpload"

import { getPageAssetUploadPath } from "@/utils/path"
import {
	getUrlFromBackgroundProp,
	buildBackgroundPropFromUrl,
	getColorFromBorderProp,
	isValidCSSColor
} from "@/utils/css"

import useStyles from "@/pages/PageList/PageEdit/PageDesignerEditDrawer/styles"

type EditorType = "background" | "text" | "button"

type PageDesignerEditDrawerProps = {
	sitePageId: number
	previewPageStyles: PageStyles
	onPageDesignSave: () => (Promise<void> | void)
}

type Tab = {
	value: EditorType
	label: string
}

type EditablePageStyles = {
	general: {
		background: string
	},
	text: {
		fontWeight: React.CSSProperties["fontWeight"]
		fontStyle: string
		fontFamily: string
		color: string
	}
	button: {
		textColor: string
		color: string
		borderRadius: string
		variant: ButtonType["variant"]
	}
}

const TABS: Tab[] = [
	{
		value: "background",
		label: "Fundo"
	},
	{
		value: "text",
		label: "Texto"
	},
	{
		value: "button",
		label: "Botões"
	}
]

const PageDesignerEditDrawer: React.FC<PageDesignerEditDrawerProps> = (props) => {
	const { sitePageId, previewPageStyles, onPageDesignSave } = props

	const classes = useStyles()

	const pageAssetUploadPath = getPageAssetUploadPath(sitePageId)

	const [editorType, setEditorType] = useState<EditorType>("background")
	const [editablePageStyles, setEditablePageStyles] = useState<EditablePageStyles>({} as EditablePageStyles)

	const buildEditablePageStyles = (pageStyles: PageStyles) => {
		const editableStyles = {} as EditablePageStyles

		editableStyles.general = {
			background: pageStyles?.general?.background_default || "#F8F9FA"
		}

		editableStyles.text = {
			fontWeight: pageStyles?.typography?.main_title_font_weight || 500,
			color: pageStyles?.typography?.main_title_font_color || "",
			fontFamily: pageStyles?.typography?.main_title_font_family || "Work Sans",
			fontStyle: pageStyles?.typography?.main_title_font_style || "normal"
		}

		/**
		 * Give priority to get from main_title but uses others if needed.
		 * It is done to help on retroactive users that does not have main title color.
		 */
		editableStyles.text.color = (
			pageStyles?.typography?.main_title_font_color ||
			pageStyles?.general?.text_primary_color ||
			pageStyles?.general?.text_secondary_color ||
			""
		)

		editableStyles.button = {
			borderRadius: pageStyles?.button?.primary_button_border_radius || "",
			textColor: pageStyles?.button?.primary_button_color || ""
		} as EditablePageStyles["button"]

		/**
		 * Gives priority to get color from border,
		 * and so to background color. It is done just to help on
		 * retroactive users that does not have border set.
		 */
		editableStyles.button.color = pageStyles?.button?.primary_button_border === "none" ? (
			getColorFromBorderProp(pageStyles?.button?.primary_button_background_color || "") || ""
		) : (
			getColorFromBorderProp(pageStyles?.button?.primary_button_border || "") || ""
		)

		editableStyles.button.variant = (
			pageStyles?.button?.primary_button_background_color === editableStyles.button.color ? (
				"contained"
			) : (
				"outlined"
			)
		)

		return editableStyles
	}

	const updatePageStyles = async () => {
		try {
			const updatedStyles = [
				{
					id: previewPageStyles?.general?.id,
					properties: {
						background_default: editablePageStyles?.general?.background,
						background_paper: editablePageStyles?.general?.background,
						text_primary_color: editablePageStyles?.text?.color,
						text_secondary_color: editablePageStyles?.text?.color
					} as PageStyles["general"]
				},
				{
					id: previewPageStyles?.paragraph?.id,
					properties: {
						main_paragraph_font_color: editablePageStyles?.text?.color,
						main_paragraph_font_style: editablePageStyles?.text?.fontStyle,
						main_paragraph_font_weight: editablePageStyles?.text?.fontWeight,
						main_paragraph_font_family: editablePageStyles?.text?.fontFamily
					} as PageStyles["paragraph"]
				},
				{
					id: previewPageStyles?.typography?.id,
					properties: {
						main_title_font_color: editablePageStyles?.text?.color,
						main_title_font_style: editablePageStyles?.text?.fontStyle,
						main_title_font_weight: editablePageStyles?.text?.fontWeight,
						main_title_font_family: editablePageStyles?.text?.fontFamily,
						secondary_title_font_color: editablePageStyles?.text?.color,
						secondary_title_font_style: editablePageStyles?.text?.fontStyle,
						secondary_title_font_weight: editablePageStyles?.text?.fontWeight,
						secondary_title_font_family: editablePageStyles?.text?.fontFamily
					} as PageStyles["typography"]
				},
				{
					id: previewPageStyles?.button?.id,
					properties: {
						primary_button_color: editablePageStyles?.button?.textColor,
						primary_button_background_color: editablePageStyles?.button?.variant === "contained" ? (
							editablePageStyles?.button?.color
						) : (
							"transparent"
						),
						primary_button_border_radius: editablePageStyles?.button?.borderRadius,
						primary_button_border: `1px solid ${editablePageStyles?.button?.color}`,
						primary_icon_button_color: editablePageStyles?.text?.color,
						primary_icon_button_border: `1px solid ${editablePageStyles?.text?.color}`
					} as PageStyles["button"]
				}
			]

			await ApiService.put("/site/page-styles/bulk", {
				updatedStyles
			})

			await onPageDesignSave()

			return true
		} catch (error) {
			ErrorHandlerService.handle(error)
			return false
		}
	}

	const handleChangeEditorType = (editorType: EditorType) => {
		setEditorType(editorType)
	}

	const handleChangeGeneral = (key: keyof EditablePageStyles["general"], value: string) => {
		setEditablePageStyles(lastState => ({
			...lastState,
			general: {
				...lastState.general || {},
				[key]: value
			}
		}))
	}

	const handleChangeText = (key: keyof EditablePageStyles["text"], value: unknown) => {
		setEditablePageStyles(lastState => ({
			...lastState,
			text: {
				...lastState.text || {},
				[key]: value
			}
		}))
	}

	const handleChangeButton = (key: keyof EditablePageStyles["button"], value: unknown) => {
		setEditablePageStyles(lastState => ({
			...lastState,
			button: {
				...lastState.button || {},
				[key]: value
			}
		}))
	}

	const handleBackgroundPictureUpload = async (response: UploadFileResponse<unknown>) => {
		const pictureUrl = response?.url

		const backgroundProp = buildBackgroundPropFromUrl(pictureUrl || "")

		handleChangeGeneral("background", backgroundProp)
	}

	useEffect(() => {
		setEditablePageStyles(buildEditablePageStyles(previewPageStyles))
	}, [previewPageStyles])

	return (
		<EditDrawer
			title="Personalizar aparência"
			description="Personalize o tema que deseja aplicar à sua página."
			onSave={updatePageStyles}
			formComponent={(
				<Grid
					container
					spacing={5}
				>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Tabs
							value={editorType}
							indicatorColor="primary"
							textColor="primary"
							onChange={(_, value: EditorType) => handleChangeEditorType(value)}
							variant="fullWidth"
							className={classes.tabs}
							classes={{
								indicator: classes.tabIndicator
							}}
						>
							{TABS.map(tab => (
								<Tab
									key={tab.value}
									value={tab.value}
									label={tab.label}
									className={classes.tab}
									classes={{
										selected: classes.selectedTab
									}}
								/>
							))}
						</Tabs>
					</Grid>

					{editorType === "background" && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									COR
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ColorPicker
									value={isValidCSSColor(editablePageStyles.general?.background) ? (
										editablePageStyles.general?.background
									) : (
										""
									)}
									onChange={(color) => handleChangeGeneral("background", color)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									IMAGEM
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ImageUpload
									requestPath={pageAssetUploadPath}
									pictureUrl={getUrlFromBackgroundProp(editablePageStyles.general?.background) || ""}
									onPictureUploaded={handleBackgroundPictureUpload}
									classes={{
										buttonContainer: classes.imageUploadLabelContainer,
										container: classes.imageUploadContainer
									}}
								/>
							</Grid>
						</>
					)}

					{editorType === "text" && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									TIPO DE LETRA
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<FontSelect
									value={{
										fontFamily: editablePageStyles.text?.fontFamily,
										fontWeight: editablePageStyles.text?.fontWeight,
										fontStyle: editablePageStyles.text?.fontStyle
									}}
									onChange={(font) => {
										handleChangeText("fontFamily", font.fontFamily)
										handleChangeText("fontWeight", font.fontWeight)
										handleChangeText("fontStyle", font.fontStyle)
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									COR DA LETRA
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ColorPicker
									value={editablePageStyles.text?.color}
									onChange={color => handleChangeText("color", color)}
								/>
							</Grid>
						</>
					)}

					{editorType === "button" && (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									ESTILO DO BOTÃO
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ButtonSelect
									value={{
										borderRadius: editablePageStyles.button?.borderRadius,
										variant: editablePageStyles.button?.variant
									}}
									onChange={(button) => {
										handleChangeButton("borderRadius", button.borderRadius)
										handleChangeButton("variant", button.variant)
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									COR DO BOTÃO
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ColorPicker
									value={editablePageStyles.button?.color}
									onChange={color => handleChangeButton("color", color)}
								/>
							</Grid>

							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Typography
									variant="button"
								>
									COR DO TEXTO DO BOTÃO
								</Typography>

								<Divider orientation="horizontal" size={2} />

								<ColorPicker
									value={editablePageStyles.button?.textColor}
									onChange={color => handleChangeButton("textColor", color)}
								/>
							</Grid>
						</>
					)}
				</Grid>
			)}
			pureChildren
		>
			<Fab
				size="medium"
			>
				<DesignerIcon
					color="primary"
					fontSize="large"
				/>
			</Fab>
		</EditDrawer>
	)
}

export default PageDesignerEditDrawer
