import { useState } from "react"
import { AxiosError } from "axios"

type ClientError = {
	messages: {
		[field: string]: string | React.ReactNode
	}
}

type ParseErrorResponse = {
	validationMessages: {
		[field: string]: string
	}
}

type ValidationError = ClientError | AxiosError

type Validation = ParseErrorResponse["validationMessages"]

// eslint-disable-next-line
const parseError = (error: any): ParseErrorResponse => {
	const validationMessages = error?.messages || error?.response?.data?.messages

	return {
		validationMessages
	}
}

const useValidation = () => {
	const [messages, setMessages] = useState<Validation>({} as Validation)

	const triggerValidation = (error: ValidationError) => {
		const data = parseError(error)

		if (data.validationMessages) {
			setMessages((lastMessages) => ({
				...lastMessages,
				...data.validationMessages
			}))
		}
	}

	const clearValidation = (key: string) => {
		if (key in messages) {
			setMessages((lastMessage) => {
				const updatedData = { ...lastMessage }

				delete updatedData[key]

				return updatedData
			})
		}
	}

	return {
		triggerValidation,
		clearValidation,
		messages
	}
}

export default useValidation
