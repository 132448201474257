import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Card, Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	card: {
		width: "100%",
		height: 260,
		backgroundColor: "#FFFFFF",
		borderRadius: 4
	}
})

const PageListSkeleton = () => {
	const classes = useStyles()

	const cards = []

	const totalItems = 2

	// eslint-disable-next-line
	for (let i = 0; i < totalItems; i++) {
		cards.push(
			<Grid
				key={i}
				item
				xs={6}
				sm={6}
				md={6}
				lg={6}
				xl={6}
			>
				<Card
					className={classes.card}
					elevation={2}
				>
					<Skeleton variant="rect" width="100%" height="100%" animation="wave" />
				</Card>
			</Grid>
		)
	}

	return (
		<Grid container spacing={3}>
			{cards}
		</Grid>
	)
}

export default PageListSkeleton
