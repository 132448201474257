import React, { useState } from "react"
import lottie from "lottie-web"
import {
	Grid,
	ThemeProvider,
	Dialog,
	Typography
} from "@material-ui/core"

import {
	Divider
} from "@/components"

import theme from "@/styles/theme"

import { renderComponent } from "@/utils/node"

import useDidMount from "@/hooks/useDidMount"

import useStyles from "@/components/SuccessModal/styles"

import successAnimation from "@/assets/lottie-animations/success.json"

type SuccessModalProps = {}

type SuccessModalType = {
	open: (props: SuccessModalProps) => void
}

const ANIMATION_ID = "success-modal-loading-lottie-animation"

const SuccessModal: SuccessModalType & React.FC<SuccessModalProps> = () => {
	const [opened, setOpened] = useState(true)

	const classes = useStyles({ opened })

	const handleClose = () => {
		setOpened(false)
	}

	const initAnimation = () => {
		/**
		 * This 'setTimeout' is a workaround to start animation
		 * after modal is shown.
		 */
		setTimeout(() => {
			const animationElement = document.getElementById(ANIMATION_ID) as Element

			const animation = lottie.loadAnimation({
				container: animationElement,
				renderer: "canvas",
				loop: false,
				autoplay: true,
				animationData: successAnimation
			})

			animation.addEventListener("complete", () => {
				handleClose()
			})
		}, 0)
	}

	useDidMount(() => {
		initAnimation()
	})

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				PaperProps={{
					className: classes.paper
				}}
			>
				<Grid
					container
					className={classes.dialogContainer}
				>
					<Grid
						container
						direction="column"
						justify="center"
						alignItems="center"
					>
						<Grid id={ANIMATION_ID} className={classes.animation} />

						<Divider orientation="horizontal" size={2} />

						<Typography
							variant="body1"
							align="center"
						>
							Alterações publicadas com sucesso!
						</Typography>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

SuccessModal.open = (props: SuccessModalProps) => {
	renderComponent(
		"success-modal",
		<SuccessModal
			{...props}
		/>
	)
}

export default SuccessModal
