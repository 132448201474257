import React from "react"
import { Grid, ButtonBase } from "@material-ui/core"

import { turnHexColorInCSSFilter } from "@/utils/color"

import useStyles from "@/@fp/components/OrganizationBrand/styles"

type OrganizationBrandProps = {
	src: string
	hexColor: string
	containerStyle?: React.CSSProperties
	url?: string
}

const OrganizationBrand: React.FC<OrganizationBrandProps> = (props) => {
	const {
		src,
		hexColor,
		containerStyle,
		url
	} = props

	const classes = useStyles()

	const colorInCSSFilter = turnHexColorInCSSFilter(hexColor)

	return (
		<Grid
			container
			alignItems="center"
			justify="center"
			style={containerStyle}
			{...(url ? {
				component: ButtonBase,
				href: url,
				target: "_blank"
			} : {})}
		>
			<img
				src={src}
				className={classes.organizationBrand}
				alt="organization brand"
				style={{
					filter: colorInCSSFilter
				}}
			/>
		</Grid>
	)
}

export default OrganizationBrand
