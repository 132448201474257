import React from "react"
import {
	Card
} from "@material-ui/core"

import useStyles from "@/components/PageCard/styles"

type PageCardProps = {
	backgroundPictureSrc?: string
	elevation?: number
	onClick?: () => void
	style?: React.CSSProperties
}

const PageCard: React.FC<PageCardProps> = (props) => {
	const {
		children,
		backgroundPictureSrc,
		elevation,
		style,
		onClick
	} = props

	const classes = useStyles()

	return (
		<Card
			elevation={elevation ?? 2}
			className={classes.card}
			onClick={onClick}
			style={{
				backgroundImage: backgroundPictureSrc ? `url(${backgroundPictureSrc})` : "",
				cursor: onClick ? "pointer" : "inherit",
				...style
			}}
		>
			{children}
		</Card>
	)
}

export default PageCard
