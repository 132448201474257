import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

const useStyles = makeStyles(theme => ({
	videoContainer: {
		position: "relative",
		width: "100%",
		height: 0,
		paddingBottom: "56.25%",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		cursor: "pointer",
		"&:hover .outside": {
			fill: "#f00",
			fillOpacity: 1
		}
	},
	videoIframe: {
		border: "none",
		width: "100%",
		height: "100%",
		backgroundSize: "cover",
		backgroundPosition: "center",
		position: "absolute",
		top: 0,
		left: 0
	},
	youtubeIconContainer: {
		position: "absolute",
		height: "100%",
		width: "100%",
		top: 0,
		left: 0
	},
	youtubeIcon: {
		width: 68,
		height: 48,
		"& .outside": {
			transition: "all 0.15s ease",
			fill: "#212121",
			fillOpacity: 0.8
		}
	},
	youtubeNavbar: {
		padding: theme.spacing(1.5),
		backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))"
	},
	youtubeNavbarTitle: {
		color: colors.greyScale[11],
		maxWidth: "100%",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		overflow: "hidden",
		flex: 1,
		fontSize: 14
	}
}))

export default useStyles
