import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	helpIcon: {
		verticalAlign: "bottom",
		marginLeft: theme.spacing(1)
	}
}))

export default useStyles
