import { makeStyles } from "@material-ui/core"

import colors from "@/styles/colors"

type UseStylesProps = {
	typographyVerticalSpacing: number
}

const useStyles = makeStyles({
	selectedButton: {
		border: "none"
	},
	selectedIcon: {
		width: 22,
		height: 22,
		right: -15,
		minHeight: 22,
		"& svg": {
			height: 18,
			width: 18
		}
	},
	buttonContainer: {
		flexBasis: "20%"
	},
	fontExample: {
		lineHeight: "24px",
		color: colors.greyScale[1],
		fontSize: 30
	},
	buttonGroupContainer: {
		marginBottom: (props: UseStylesProps) => `-${props.typographyVerticalSpacing}px`
	}
})

export default useStyles
